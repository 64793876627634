const MachineLearningQuestions = [
    {
        id: 1,
        question: "If I’m stuck on a technical implementation, can I get any assistance?",
        answer: "Yes, we provide plans through our membership portal where you can select an option for solutions engineers to install and guide through the cryptography protocols of CipherTEA. Please view the ‘membership’ page for more details on this. Otherwise, we have thorough documentation that answers many commonly occurring scenarios and provides ease in implementing our systems locally.",
        isOpen: false,
    },
      {
        id: 2,
        question: "Why does CipherTEA provide core existing cryptography implementations along with the research framework? Why not just publish the product from research project?",
        answer: "This is a good question. The founder of CipherTEA wanted to still provide access of core cryptographic mechanisms for client(s) that don’t feel the need or have the comfort to use the CipherTEA protocol for their systems. They maybe more familiar with the standard practices that have been in practice and have the infrastructure already in place to store data very securely. ",
        isOpen: false,
    },
      {
        id: 3,
        question: "When was CipherTEA founded and why?",
        answer: "CipherTEA was founded by a Software Engineer who is former grad student in September of 2023 after peer reviewed research articles were approved in May of 2021. The goal of the research was to  improve on the non-secure existing implementation practices of cryptography. The general idea behind having to store and transfer a private key through a completely secure and ephemeral exchange and storage protocol makes the process very complex. There was also no singular software system that could be relied on for these practices. The research suggested a solution to these problems by changing the philosophy that instead relied on the software entity rather than the standalone practices for cryptography.",
        isOpen: false,
    },
];

export default MachineLearningQuestions;