import React from "react";
import { Formik } from "formik";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../services/OpenAPI";

const Form = () => {

  const navigate = useNavigate();

  const initialValues = {
    name: "",
    email: "",
    company_name: "",
    reason: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    signUp(values).then((response) => {
      alert("You have been added to the waitlist!");
      navigate("/wait-list");
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div class="sign-up-form">
            <div class="input-container">
              <InputField
                type="text"
                label="Full Name"
                name="name"
                value={values.name}
                setValue={handleChange}
                errorMessage={errors.name}
              />
              <InputField
                type="text"
                label="Email"
                name="email"
                value={values.email}
                setValue={handleChange}
                errorMessage={errors.email}
              />
              <InputField
                type="text"
                label="Company Name"
                name="company_name"
                value={values.company_name}
                setValue={handleChange}
                errorMessage={errors.company_name}
              />
              <TextArea
                value={values.reason}
                onChange={handleChange}
                name={"reason"}
                rows={4}
                cols={20}
                placeholder={"Reason for joining (optional)"}
              />
              
            </div>
            <div class="btn-links-contianer">
              <Button text={"Submit"} disabled={false} />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Form;
