import React, {useState} from 'react'
import MainContent from '../components/MainContent';
import Modal from '../components/Modal';
import { Link } from "react-router-dom";
import { paragraphs } from '../staticData/ModalData';
import { content } from '../staticData/MainContentData';
import { agencydata } from '../staticData/AgenyAreaData'
import AgencyArea from '../components/AgencyArea';


const Index = () => {
    
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <div class="grid md-cols-2 lg-cols-3 header-grid-container">
                <div class="cipher">
                    <h1 class="one font-bold" data-aos='fade-down'
                        data-aos-delay='500'>What is CipherTEA?</h1>
                    <h1 class="two font-bold" data-aos='fade-down'
                        data-aos-delay='600'>Why You Need Us?</h1>
                    <p class="paragraph" data-aos='fade-up'
                        data-aos-delay='500'>An innovative API-driven cryptography framework. Designed to enhance data security at the application layer safeguarding data-at-rest right before it enters various storage systems.</p>
                    <div class="flex cipher2">
                        <button class="btn" data-aos='fade-down'
                            data-aos-delay='600'>Get Started</button>
                        <p class="learn" data-aos='fade-down'
                            data-aos-delay='700'><a href="#imagepart" style={{ color: 'black' }}>LEARN MORE
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="9" viewBox="0 0 26 9" fill="none">
                                <path d="M25.3536 4.85355C25.5488 4.65829 25.5488 4.34171 25.3536 4.14645L22.1716 0.964466C21.9763 0.769204 21.6597 0.769204 21.4645 0.964466C21.2692 1.15973 21.2692 1.47631 21.4645 1.67157L24.2929 4.5L21.4645 7.32843C21.2692 7.52369 21.2692 7.84027 21.4645 8.03553C21.6597 8.2308 21.9763 8.2308 22.1716 8.03553L25.3536 4.85355ZM0 5H25V4H0V5Z" fill="black" />
                            </svg>
                            </a>
                        </p>
                    </div>
                </div>
        
                <div class="flex video-part" data-aos="fade-up-left" data-aos-delay='700'>
                    <video src="/assests/images/landingpage/video.mp4" controls loop autoPlay muted class="video">
                    </video>
                </div>
            </div>

            <div className='nutshell-container'>
            <h1 data-aos='fade-down' data-aos-delay='500'>IN A NUTSHELL</h1>
            <p data-aos='fade-down' data-aos-delay='600'>CipherTEA is a pioneering Cryptography-as-a-Service platform, whose foundation began with a research project dedicated to enhancing <strong>symmetric (also known as Private-Key) encryption</strong> methodologies by two major strategies. </p>
                <span>
                    <span className='nutshell-link-bold' data-aos='fade-down' data-aos-delay='600'><em>Curious about data security and the contemporary challenges it faces? </em></span>
                    <span
                        className='nutshell-link'
                        onClick={() => setIsOpen(true)}
                        data-aos='fade-down' data-aos-delay='700' >
                        <em>Click here to learn about encryption and its contemporary challenges. </em>
                    </span>
                </span>
                <p class="end-para" data-aos='fade-down' data-aos-delay='800'>The first major problem is that many companies lack the expertise to perform encryption for their data.
                    Programming languages can be verbose and developers and security engineers don’t have the familiarity with the principles of efficiently securing data.
                    Their inefficient implementation strategies can cause their data protection to be ineffective in the case of a data breach.
                    Surprisingly, many companies don’t even encrypt their data either because many compliance measures don’t require them to or they are simply not compliant or audited.
                    CipherTEA provides a simple and cohesive API-driven software that is a one-stop resource for performing all standard cryptographic schemes. The software is also programming-language-agnostic and does not require in-depth expertise on cryptographical methods. These APIs are concise and well-documented for nearly all use cases and prevent the programmer from making any mistake during implementation.
                    <br/><br/>
                    The second major problem is that although cryptographic algorithms are secure, if their implementation is not performed efficiently, they can open up ways for adversaries to break into the system.
                    CipherTEA provides a peer-reviewed original framework that adds obfuscations on top of pre-existing NIST-approved algorithms, e,g., AES Modes of encryption to perform cryptographical schemes. Meaning, only the authenticated users can perform decryption that can only be interpreted by the CipherTEA framework. This removes the need for a KMS or HSM to store the private key separately. In the event of a data breach, if the adversary has access to all the data they will not be able to decrypt the encrypted data using any other mechanism because only the CipherTEA framework can decrypt it.
                </p>
                <p> <em><strong>”Still have questions?</strong> <Link to="/FAQ" style={{ color: 'black' }}>Read our docs <strong> or</strong> click here for our most frequently asked questions.”</Link></em></p>
            </div>

            {/* Model here */}
            <Modal isOpen={isOpen} onClose={onClose} content={paragraphs}/>
            {/* next sectionnnn */}

            <section class="agency-area">
                <div class="container mx-auto text-center font-poppins">
                    <p class="txt" data-aos='fade-down'
                        data-aos-delay='500'>Why You Should Act Fast</p>
                    <div class="area-title">
                        <h1 class="text-lg text-dark font-bold" data-aos='fade-down'
                            data-aos-delay='500'>
                            What do the statistics say?
                        </h1>
                    </div>
                    <div class="grid cols-1 lg-cols-4 py-5" data-aos="zoom-in-up">
                        {agencydata.map((data) => (
                            <AgencyArea  data={data}/>
                        ))}
                    </div>
                </div>
            </section>

            <section id='imagepart'><MainContent data={content} /></section>
        </>
    )
}

export default Index
