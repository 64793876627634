import React, { useEffect, useState } from "react";
import "../../../styles/BlogChild.css";
import BackButton from "../../../components/BackButton";
import { Link } from "react-router-dom";

const BlogChild = () => {
  const [blog, setBlog] = useState({});

  useEffect(() => {
    const blogData = JSON.parse(localStorage.getItem("blogData"));
    setBlog(blogData);
  }, []);


  return (
      <div className="flex consistent-margins" id="blog-container">
        <Link to="/Blog">
        <BackButton buttonText="Index"/>
        </Link>
        {blog && (
          <>
            <h1>{blog.title}</h1>
            <span>{blog.author}</span>
            <span class="date">{blog.date}</span>
            <div className="hashtags">
              {blog.hashtags &&
                blog.hashtags.map((tag, index) => (
                  <span key={index} className="hashtag">
                    #{tag}
                  </span>
                ))}
            </div>
            {blog.posts &&
              blog.posts.map((post, index) => {
                return (
                  <div key={index}>
                    <h2>{post.headerData.header}</h2>
                    <p>{post.headerData.text}</p>
                    {post.header2Data ? (
                      <h3>{post.header2Data.header}</h3>
                    ) : null}
                    {post.header2Data ? <p>{post.header2Data.text}</p> : null}
                    {post.Image ? <img src={post.Image} alt=""/> : null}
                    {post.ParentImage ? (
                      <img src={post.ParentImage} alt="" />
                    ) : null}
                    {post.subheading ? (
                      <p class="subheading">{post.subheading}</p>
                    ) : null}
                    {post.bullets && (
                      <ul>
                        {post.bullets.map((bullet, bulletIndex) => (
                          <li key={bulletIndex} class="circle-bullet">
                            {bullet.bullet}
                            {bullet.sub_bullet &&
                              bullet.sub_bullet.map(
                                (subBullet, subBulletIndex) => (
                                  <ul key={subBulletIndex}>
                                    <li class="circle-subbullet">
                                      {subBullet}
                                    </li>
                                  </ul>
                                )
                              )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
          </>
        )}
      </div>
  );
};

export default BlogChild;
