import React from "react";

const AgencyArea = ({ data }) => {
    return (
        <div key={data.id} class="grid-item" data-aos='fade-down'
            data-aos-delay='600'>
            <div class=" rellax" data-rellax-speed="2">
                <div class="card">
                    <span class="d-block text-dark sm-text-xl">
                        <div class="leftIcon">
                            {data.icon}
                        </div>
                    </span>
                    <p class="data py-2 font-bold">
                        {data.title}
                    </p>
                    <p class="data1">
                        {data.text}
                    </p>
                </div>
            </div>
        </div>

    );
};

export default AgencyArea;