import React, { useState } from "react";
import "../../styles/login.css";
import BusinessForm from "./BusinessForm";
import IndividualForm from "./IndividualForm";
import { useNavigate } from "react-router-dom";
import FlipCard from "./FlipCard";
import { CorporateIcon, IndividualIcon } from "../../components/icons";

// Define Data for Flip cards
const flipCardsData = [
  {
    title: "Enterprise",
    items: ['For users 3 or more.']
  },
  {
    title: "Consumer",
    items: ['For 1-2 users.']
  }
]

const Registration = () => {
  const [signupData, setSignupData] = useState({ 
    name: "", 
    lastName: "",
    phone: "",
    email: "", 
    password: "",
    businessType: "",
    businessName: "",
  });
  const navigate = useNavigate();

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const updatedSignupData = { ...signupData };
    updatedSignupData[name] = value;
    setSignupData(updatedSignupData);
  };

  const handleSignupClick = () => {
    navigate("/login");
  };

  return (
    <div class="main-container">
      <div className='login-header-container'>
        CipherTEA
      </div>
      <div class="login-container">
        <div class="extra-container">
          <div class="header-container">
            <span>Create Your Account</span>
            <p>Sign up to CipherTEA</p>
          </div>

          {signupData.businessType === "" && 
            <div className="business-option-container">
              <FlipCard
                handler={() => setSignupData({ ...setSignupData, businessType: "corporate"})}
                content={flipCardsData[0]}
                Icon={CorporateIcon}
              />

              <FlipCard
                handler={() => setSignupData({ ...setSignupData, businessType: "individual"})}
                content={flipCardsData[1]}
                Icon={IndividualIcon}
              />
            </div>
          }
          
          {signupData.businessType === "corporate" &&
            <BusinessForm businessType={ signupData.businessType } />
          }

          {signupData.businessType === "individual" &&
            <IndividualForm
              signupData={signupData}
              handleValueChange={handleValueChange}
              handleSignupClick={handleSignupClick}
            />
          }

        </div>
      </div>
    </div>
  );
};

export default Registration;
