import axios from "axios";
const API_BASE_URL = "https://waitlist-backend-puce.vercel.app";

export const signUp = async (data) => {
    const response = await axios.post(`${API_BASE_URL}/api/wait-list/register`, data);
    return response;
}

export const getAllUsers = async () => {
    const response = await axios.get(`${API_BASE_URL}/api/wait-list/users`);
    return response;
}

export const setFlag = async (data) => {
    const response = await axios.post(`${API_BASE_URL}/api/wait-list/flag`, data);
    return response;
}

export const getFlag = async (key) => {
    const response = await axios.get(`${API_BASE_URL}/api/wait-list/flag?key=${key}`);
    return response.data;
}