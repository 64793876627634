import React from "react";

const BlogBottomForm = () => {
  return (
    <div className="bottom-container">
      <div className="bottom-main-cont">
        <div className="bottom-content-cont">
          <div className="bottom-header-cont">
            <h1>We Dont Want To Lose You!</h1>
            <span>
              Get our Monthly Digest right into your Mailbox & always stay
              updated <br></br> & ahead Of the game!
            </span>
          </div>
          <form className="blog-form">
            <div className="email-cont">
              <label className="bottom-form-label" htmlFor="email">
                Email*
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="bottom-input-field"
              />
            </div>
            <div className="email-cont">
              <label className="bottom-form-label" htmlFor="interest">
                What Are You Interested in?*
              </label>
              <input
                type="text"
                name="interest"
                id="interest"
                className="bottom-input-field"
              />
            </div>
            <button className="bottom-submit-btn">Subscribe</button>
          </form>
        </div>
        <div className="bottom-image">
          <img src="/assests/images/blogParent/BottomCard.svg" alt="" />
        </div>
      </div>
    </div>
  );
};
export default BlogBottomForm;
