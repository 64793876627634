const FAQTabs = [
    {
        id: 1,
        title: "Account & Profile"
    },
    {
        id: 2,
        title: "Integration"
    },
    {
        id: 3,
        title: "Trouble Shouting"
    },
    {
        id: 4,
        title: "Billing & Payments"
    },
    {
        id: 5,
        title: "Privacy & Security"
    },
    {
        id: 6,
        title: "Machine Learning"
    },
    {
        id: 7,
        title: "Data Security"
    },
    {
        id: 8,
        title: "Everything Else"
    },
];

export default FAQTabs