
function getAPIConfigs(selectedAPi, selectedEndPoint) {
  const APIConfig = {
    endpoint: selectedAPi.baseUrl + selectedEndPoint.endpoint,
    method: selectedEndPoint.method,
  };

  if (selectedEndPoint.payloadRequest) {
    APIConfig.payloadRequest = JSON.parse(selectedEndPoint.payloadRequest);
  }

  for (const key in selectedEndPoint.params) {
    if (selectedEndPoint.params[key] !== "") {
      APIConfig.params = {
        ...APIConfig.params,
        [key]: selectedEndPoint.params[key],
      };
    }
  }

  for (const key in selectedEndPoint.headers) {
    if (selectedEndPoint.headers[key] !== "") {
      APIConfig.headers = {
        ...APIConfig.headers,
        [key]: selectedEndPoint.headers[key],
      };
    }
  }

  return APIConfig;
}


export { getAPIConfigs };
export { default as getLanguageCode } from './LanguageCodeSelectorUtil';
export { default as parseApi } from './ApiParserUtil';