import React from "react";

const MainContent = ({ questions, setQuestions }) => {
  const handleToggleBtn = (index) => {
    // update changes with pass by value
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      isOpen: !newQuestions[index].isOpen,
    };

    // update question
    setQuestions(newQuestions);
  };

  return (
    <div className="questions-main-container">
      {questions && questions?.map((question, index) => {
        return (
          <div key={index} onClick={() => handleToggleBtn(index)} className={`main-box ${question.isOpen && "open-question-box"}`}>
            <div className={`question-main-box`}>
              <div className="question">{question.question}</div>
              {question.isOpen && (
                <div className="answer-box">
                  <div className="answer">{question.answer}</div>
                </div>
              )}
            </div>
            <div onClick={() => handleToggleBtn(index)}>
              {question.isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="arrow-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="arrow-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MainContent;
