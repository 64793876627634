const background = {
    title: 'Research Background',
    para1: `Before applying this idea for corporations, CipherTEA, Inc. was a research project which began in Summer of 2021. Atif is the first author and publisher for the research. 
    He performed this research with his advisor, Dr Osama AbuOmar at Lewis University during and after his final course of Masters Thesis of his graduate school program. the first 
    paper they wrote is titled “Developing an API for block-cipher Encryption powered by Supervised Learning”. This project was published in the IEEE (Institute of Electrical and 
    Electronics Engineers) conference for CSCI (International Conference on Computational Science). Atif ultimately decided to incorporate the subject which he took a few months 
    before graduating called 'Encryption and Authentication'. He also used his expertise in Software Design and development, particularly API development, to build the entire proof
    of concept for the project, using Java and Spring Boot. `,
    para2: `The Encryption course provided Atif with the necessary education and fundamental understanding of all principles and 
    history surrounding cryptography. This project was aimed at building a software system as a single resource that provides cryptographic schemes via RESTful APIs. It aimed for 
    providing simplicity and compact code that can be easily integrated with any software.  After publishing the first paper, Atif wrote another paper which iterated from the first 
    one called “A Secure API-driven framework for AES Modes of Encryption. This article was also published in an IEEE conference for EIT. This is article where the notation
    ‘CipherTEA’ was created. This notation abbreviated as ‘ct’ aimed at strengthening the weakness of Symmetric Encryption which was that key management can have vulnerabilities. 
    Private keys must be transferred through an secure ephemeral key exchange protocol and if implementation is improper or the private key gets compromised, then the entire scheme 
    becomes a useless feat. The premise of adding obfuscations over the encrypted data called ciphertext and the private key and the IV, is what came to be known as CipherTEA. The 
    TEA actually stands for Text Encryption & Authentication. `,
}

const researchPapers = [
    {
        id: 1,
        title: `Developing an API for Block-Cipher Encryption powered by Supervised Learning`,
        paper: `The Advanced Encryption Standard (AES) is a widely adopted cryptographic algorithm, providing robust security. Despite its strength, potential threats from quantum computing and decryption attempts persist. This research explores the integration of machine learning (ML) and cryptography, proposing an API-driven framework for encryption and decryption. By leveraging ML, the framework enhances security by introducing abstracted layers over encrypted data, making it interpretable only by the developed system. The API deployment aims to create a unified system, addressing vulnerabilities and demonstrating ML's effectiveness against cyber threats.`,
        abstract: `In the realm of cryptography, the Advanced Encryption Standard (AES) stands as a widely adopted class of algorithms. While these algorithms offer robust security, they aren't impervious to threats, whether from the rise of quantum computing or adversaries attempting decryption. This research delves into the potential synergy between machine learning (ML) and cryptography, presenting an innovative API-driven framework for encryption and decryption. By leveraging ML, this framework introduces abstracted layers of security over encrypted data, rendering it interpretable solely by the developed system. The deployment of this API aims to create a unified system that mitigates various vulnerabilities, showcasing the role of ML as a potent tool in countering cyber threats.
        This publication highlights the development of API framework for Block-Cipher Encryption, a research framework to enhance security against cyber attacks & data breaches. `,
        Publisher:'Publisher: IEEE',
        publisher: `IEEE`,
        author: `Atif Tirmizi, Osama Abuomar & Khaled M. Alzoubi`,
        Author: 'Author: Atif Tirmizi, Osama Abuomar & Khaled M. Alzoubi',
        Date: 'Date: 22 June 2022',
        PaeprLink: 'https://ieeexplore.ieee.org/document/9798940/figures#figures',
        GoogleScholar: `https://scholar.google.com/citations?view_op=view_citation&hl=en&user=W20_SV0AAAAJ&citation_for_view=W20_SV0AAAAJ:u5HHmVD_uO8C`,
    },
    {
        id: 2,
        title: `A Secure API-Driven Framework for AES Modes of Encryption Enhanced with Machine Learning`,
        paper: `Cryptography, integral to Computer Science and Mathematics, manages data encryption. The widely-used Advanced Encryption Standard (AES) faces vulnerabilities from quantum computing and attacks. This study proposes an API-driven encryption framework, integrating machine learning (ML) for added security. Through techniques like Encapsulation and Abstraction, the framework ensures interpretability solely by the system, bolstering data security against cyber threats. The publication emphasizes enhancing security measures for both Data At Rest and Data In Transit through a unique API-driven framework fortified with Machine Learning.`,
        abstract: `Cryptography, a field in Computer Science and Mathematics, deals with data encryption and decryption. The Advanced Encryption Standard (AES) is a widely used class of algorithms, but it's not invulnerable to quantum computing or attacks. This study explores using machine learning (ML) to create an API-driven encryption and decryption framework that adds layers of security to encrypted data. This framework employs techniques like Encapsulation and Abstraction, making the ciphertext and key interpretable only by the framework, enhancing data security and defending against cyber threats.
        This publication was done to find a method that can further enhance the security measures for Data At Rest & Data In Transit. It brings forward a unique method to limit hackers and data breaches through a secure API-driven framework enhanced with Machine Learning.`,
        Publisher:'Publisher: IEEE',
        publisher: `IEEE`,
        author: `Atif Tirmizi, Osama Abuomar & Khaled M. Alzoubi`,
        Author: 'Author: Atif Tirmizi, Osama Abuomar & Khaled M. Alzoubi',
        Date: 'Date: 22 June 2022',
        PaeprLink: 'https://ieeexplore.ieee.org/document/9814010/metrics#metrics',
        GoogleScholar: `https://scholar.google.com/citations?view_op=view_citation&hl=en&user=W20_SV0AAAAJ&citation_for_view=W20_SV0AAAAJ:u-x6o8ySG0sC`,
    },

]

export {background, researchPapers};