import React, { useState } from "react";
import { showPasswordIconPath, hidePasswordIconPath } from "./InputFieldPathDVal";

const labelStyle = {
  top: "0px",
  fontSize: "12px",
  color: "#154360"
}
const InputField = ({ type, label, value, setValue, name, errorMessage, required=true}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const handleTogglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="input-error-fix">
      {errorMessage && <div className="error-message"><span>{errorMessage}</span></div>}
      <div className="group">
        <input
          type={passwordShown ? "text" : type}
          name={name}
          value={value}
          onChange={setValue}
          required={required}
        />
        <label style={value ? labelStyle : {}}>{label}</label>
        {type === "password" ? (
          <span className="show-password" onClick={handleTogglePassword}>
            {passwordShown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d={hidePasswordIconPath}
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className=""
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d={showPasswordIconPath}
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            )}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default InputField;
