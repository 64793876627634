import React, { useState, useEffect } from "react";
import "../../../styles/Blog.css";
import BlogParentCard from "../../../components/BlogParentCard";
import BlogBottomForm from "../../../components/BlogBottomForm";
import BlogParser from "../../../utils/ParserUtil";

const blogs = require.context("../../../content/BlogContent", true);
const blogList = blogs.keys().map((blog) => blogs(blog));

const Blog = () => {
  const [fileContent, setFileContent] = useState([]);
  const [blogContent, setBlogContent] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  let check = true;

  useEffect(() => {
    if (check) {
      for (const currBlog of blogList) {
        fetch(currBlog)
          .then((row) => row.text())
          .then((text) => {
            setFileContent((prev) => [...prev, text]);
          });
      }
      check = false;
    }
  }, []);

  useEffect(() => {
    if (fileContent.length) {
      const myBlogContent = fileContent.map((blog, index) => {
        const data = BlogParser(blog);
        data.id = index + 1;
        return data;
      });
      setBlogContent(myBlogContent);
    }
  }, [fileContent]);

  useEffect(() => {
    if (fileContent.length) {
      const myBlogContent = fileContent.map((blog, index) => {
        const data = BlogParser(blog);
        data.id = index + 1;
        return data;
      });

      myBlogContent.sort((a, b) => new Date(b.date) - new Date(a.date));

      setBlogContent(myBlogContent);
    }
  }, [fileContent]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
    }

    return () => {
      // Cleanup: set isMounted to false when the component unmounts
      isMounted = false;
    };
  }, []);

  return (
    <div class="parent-blog">
      <div class="grid md-cols-2 lg-cols-3 blog-main-container">
        <div class="blog-left-side">
          <div class="blog-heading">
            <h1>
              Everything You Need To Know About The{" "}
              <span className="cryptography"> Cryptography </span> World
            </h1>
            <p class="paragraph1">
              Encrypion, Decryption, Hashing - We've got you covered!
            </p>
            <p class="paragraph2">
              Simplify Cryptography For Businesses & Individuals!
            </p>
          </div>

          <div class="searchbar">
            <input
              placeholder="Search...."
              id="searchBar"
              name="searchBar"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            ></input>
            <svg
              class="glass-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>
          </div>
        </div>
        <div class="flex blog-right-side">
          <img src="/assests/images/blogParent/Blog-image.svg" alt="" class="image" />
        </div>
      </div>

      {blogContent
        .filter(
          (blog) =>
            blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            blog.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
            blog.hashtags.some((tag) =>
              tag.toLowerCase().includes(searchQuery.toLowerCase())
            ) ||
            blog.posts.some(
              (post) =>
                post.headerData.text
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                (post.header2Data &&
                  post.header2Data.text
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())) ||
                (post.subheading &&
                  post.subheading
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())) ||
                (post.bullets &&
                  post.bullets.some(
                    (bullet) =>
                      typeof bullet === "string" &&
                      bullet.toLowerCase().includes(searchQuery.toLowerCase())
                  ))
            )
        )
        .map((blog, i) => {
          return <BlogParentCard key={blog.id} data={blog} />;
        })}

      <BlogBottomForm />
    </div>
  );
};

export default Blog;
