import React, { useState } from "react";

const MobileResponsive = ({
  filteredApiContent,
  selectedAPi,
  isExpanded,
  handleSelectApi,
  selectedEndPoint,
  search,
  handleSearchTitle,
  handleEndPointChange
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isController, setIsController] = useState(false);
  const [isEndpoint, setIsEndpoint] = useState(false);

  const handleController = () => {
    setIsOpen(!isOpen);
    setIsController(true);
  }

  const handleEndpoint = () => {
    setIsOpen(!isOpen);
    setIsEndpoint(true);
  }

  const handleClose = () => {
    setIsOpen(false);
    setIsController(false);
    setIsEndpoint(false);
  }

  return (
    <div className="mobile-view-container">

      {/* controller and endpoint toggle buttons */}
      <div className="btn-main-container">
        <div className="menu-btn" onClick={handleController}>
          <p>Controller</p>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                fill="black"
              ></path>{" "}
            </g>
          </svg>
        </div>

        <div className="menu-btn" onClick={handleEndpoint}>
          <p>Endpoint</p>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                fill="black"
              ></path>{" "}
            </g>
          </svg>
        </div>
      </div>

      {/* Model for controllers and endpoint */}
      {isOpen && (
        <div className="api-overlay">
        <div className="api-overlay-background"></div>
        <div className="api-overlay-content">
          <div className="controller-mobile-heading">
            <span>{`${isController ? "Controllers" : "Endpoints"}`}</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" onClick={handleClose}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </div>

          {/* Controllers content */}
          {isController && (
            <div className="search-title-container">
              <div class="search-container">
                <input 
                  placeholder="Search...." 
                  id="searchBar" 
                  name="searchBar" 
                  type="text"
                  value={search}
                  onChange={handleSearchTitle}
                />
                <div className="search-icon-container">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                  </svg>
                </div>
              </div>
              <div className="title-container">
              {filteredApiContent &&
                filteredApiContent.map((api) => {
                  return (
                    <div key={api.id}>
                      <p className={`${selectedAPi.id === api.id ? "selected-title" : ""}`} onClick={() => {
                          handleSelectApi(api)
                          handleClose()
                        }}>
                        {api.title}
                      </p>
                      {selectedAPi.id === api.id && isExpanded &&
                        <ul className="title-sub-list">
                          <a href="#response"><li onClick={handleClose}>Response</li></a>
                          <a href="#static"><li onClick={handleClose}>{selectedEndPoint?.staticCodeTitle}</li></a>
                          {(selectedEndPoint.staticResponses.length > 0) && <a href="#error"><li onClick={handleClose}>Error Types</li></a>}
                        </ul>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Endpoints Content */}
          {isEndpoint && (
            <div className="mobile-endpoints">
              <ul>
                  {selectedAPi.endPoints && selectedAPi.endPoints.map((endpoint, i) => {
                    return (
                      <li key={i} onClick={() => {
                          handleEndPointChange(endpoint);
                          handleClose();
                        }} style={{'cursor': 'pointer'}}>
                        <a className={`${endpoint.endpoint === selectedEndPoint.endpoint ? "selected-endpoint" : ""}`}>{endpoint.endpoint}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}

        </div>
      </div>
      )}
    </div>
  );
};

export default MobileResponsive;
