import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import '../../styles/PasswordValidationBox.css';


const PasswordValidationBox = ({ password }) => {
    const requirements = [
        { test: (pwd) => pwd.length >= 16, text: "16 or more characters" },
        { test: (pwd) => /[A-Z]/.test(pwd) && /[a-z]/.test(pwd), text: "Upper & lowercase letters" },
        { test: (pwd) => /[0-9]/.test(pwd), text: "At least one number" },
        { test: (pwd) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(pwd), text: "At least one special character" },
        { test: (pwd) => !/(.)\1{2,}/.test(pwd), text: "No more than two identical characters in a row" }
    ];

    const metRequirements = requirements.map(req => req.test(password));
    const metCount = metRequirements.filter(Boolean).length;

    let strengthText = 'Weak';

if (metCount === requirements.length) {
    strengthText = 'Very Strong';
} else if (metCount >= 3) {
    strengthText = 'Strong';
} else if (metCount >= 2) {
    strengthText = 'Moderate';
} else if (metCount >= 1) {
    strengthText = 'Weak';
} else {
    strengthText = 'Very Weak';
}

const progressColor = {
    'Very Weak': '#ff3e3e',
    'Weak': '#ff8c1a',
    'Moderate': '#f7d038',
    'Strong': '#90ee90',
    'Very Strong': '#32cd32'
}[strengthText];
    return (
        <div className="password-validation-box">
            
            <h2>Your Password Must Have:</h2>
            <ul>
                {requirements.map((req, index) => (
                    <li key={index} className={`password-requirement ${metRequirements[index] ? 'valid' : 'invalid'}`}>
                        <FontAwesomeIcon 
                            icon={metRequirements[index] ? faCheckCircle : faTimesCircle} 
                            style={{ color: metRequirements[index] ? 'green' : 'red', marginRight: '5px' }} 
                        />
                        {req.text}
                    </li>
                ))}
            </ul>
            <div className="password-strength-text" aria-live="polite" style={{ color: progressColor }}>
                <h3>Strength: {strengthText}</h3>
            </div>
            <div className="password-strength-meter">
                <div className="progress-bar" style={{ width: `${(metCount / requirements.length) * 100}%`, backgroundColor: progressColor }}></div>
            </div>
        </div>
    );
};

export default PasswordValidationBox;

