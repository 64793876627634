import React, { useState } from "react";
import PasswordValidationBox from "../../Login/PasswordValidationBox";
import { isPasswordValid } from '../../../utils/Validations';


const PasswordForm = ({form, handleChange}) => {
  const [newPassword, setNewPassword] = useState(""); // State to manage new password
  const [reEnterNewPassword, setReEnterNewPassword] = useState(""); 

  return (
    <>
      <div className="hidden-input">
        <input
          name="oldPassword"
          placeholder="Enter Old Password"
          type="password"
          value={form.oldPassword}
          onChange={handleChange}
          required={true}
        />
      </div>
      <div className="hidden-input">
        <input
          name="newPassword"
          placeholder="Enter New Password"
          type="password"
          value={form.newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value); // Update the new password state
            handleChange(e); // Propagate the change event to parent
          }}
          required={true}
        />
        {newPassword && !isPasswordValid(newPassword) && (
          <div style={{ position: "relative" }}>
            <PasswordValidationBox password={newPassword} />
          </div>
        )}
      </div>
      <div className="hidden-input">
        <input
          name="reEnterNewPassword"
          placeholder="Re-Enter New Password"
          type="password"
          value={form.reEnterNewPassword}
          onChange={(e) => {
            setReEnterNewPassword(e.target.value); // Update the re-entered password state
            handleChange(e); // Propagate the change event to parent
          }}
          required={true}
        />
        {reEnterNewPassword && !isPasswordValid(reEnterNewPassword) && (
          <div style={{ position: "relative" }}>
            <PasswordValidationBox password={reEnterNewPassword} />
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordForm;
