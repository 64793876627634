const paragraphs = [
     `Encryption is defined as the practice that encodes data in a format
    that cannot be read or understood by an unauthorized personnel.
    There are 2 major type types of encryption: symmetric and
    asymmetric. `,
     `Asymmetric encryption is used for data-in-transfer, whereas
    symmetric encryption is used for data-at-rest. Asymmetric Encryption
    uses 2 keys (public and private) for performing encryption and
    decryption, making it more suitable for the use case. Symmetric
    encryption AKA private-key encryption uses a single private key,
    which cannot be seen by anyone else other than the authenticated
    parties communicating. This scheme requires the single private key
    to be transferred by a secure ephemeral key exchange protocol called
    Diffie Hellman key exchange and requires a Key Management System
    (KMS) or Hardware Security Module (HSM) for storing the private key
    separately from the ciphertext.  For asymmetric encryption, modern
    protocols are generally secure and not critically required by
    organizations since they transfer data via the HTTPS protocol, which
    uses SSL or TLS encryption.`,
     ` However, data-at-rest can reside anywhere including but not limited
    to: databases, file storages, cloud, data warehouses, blob, object
    storages, and many more. The problem with many storage mechanisms is
    that they don’t always provide sufficient encryption mechanisms,
    hence causing hackers or adversaries to view all data in the event
    of a data-breach.`,
     ` Another problem is that when these mechanisms are offered by data
    storages, there are too many authorized resources that can decrypt
    the data. Meaning that in a scenario where a hacker gets any of the
    admin credentials for the data storage, they can decrypt the data.`,
]

export {paragraphs};