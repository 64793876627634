import React from "react";
import '../styles/BackButton.css'

const BackButton = ({buttonText, color, isResearch}) => {
  const dynamicClass = color ? `${color}-btn` : '';
  const researchClass = isResearch ? 'research-btn' : '';

  return (
    <button  className={`flex btn ${dynamicClass} ${researchClass}`} id="back-Button">
      <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffffff"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            fill="#ffff"
            d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
          ></path>
          <path
            fill="#ffff"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
          ></path>
        </g>
      </svg>
      <p class="buttonText">{buttonText}</p>
    </button>
  );
};

export default BackButton;
