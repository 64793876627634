import React from "react";

const Tabs = ({ selectedTab, setSelectedTab, tabItems }) => {
  const handleTabChanged = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className="main-tab-container">
      <div className="extra-space" />
      {tabItems.map((tab) => {
        return (
          <div
            className={`tab-btn ${
              selectedTab.id === tab.id ? "selected-tab-btn" : "simple-tab-btn"
            }`}
            key={tab.id}
            onClick={() => handleTabChanged(tab)}
          >
            {tab.title}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
