import axios from "axios";
import { USER_API } from "../utils/OpenAPI";

const verifyLogin = async (username, password) => {
    try {
        const response = await axios.post(USER_API.VERIFY_LOGIN, {
            "user-email": username,
            "user-password": password
        });
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const saveConsumerData = async (data) => {
    try {
        const response = await axios.post(USER_API.SAVE_CONSUMER_DATA, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getUserByPrimaryInfo = async (email, password) => {
    try {
        const response = await axios.post(USER_API.GET_USER_PERSONAL_DATA, {
            "user-email": email,
            "user-password": password
        });
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const getClientById = async (clientId) => {
    try {
        const response = await axios.post(USER_API.GET_CLIENT_BY_ID, {
            "client-id": clientId,
        });
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};


const SaveBusinessData = async (data) => {
    try {
        const response = await axios.post(USER_API.SAVE_BUSINESS_DATA, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const deleteClient = async (id) => {
    const response = await axios.delete(USER_API.DELETE_CLIENT, {
        "client-id": id
    });
    return response.data;
};


export {
    verifyLogin,
    saveConsumerData,
    getUserByPrimaryInfo,
    SaveBusinessData,
    getClientById,
    deleteClient
}
