import React from "react";

const Modal = ({ isOpen, onClose, content }) => {

  return isOpen ? (
    <div className="modal-container">
      <div className="overlay" onClick={onClose}></div>
      <div className="modal-content">
        {/* Your modal content goes here */}
        <div className="model-close-cont">
          <div onClick={onClose} className="model-close-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ width: "30px", height: "30px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
        <div className="model-text-cont">
          {content.map((singleParagraph, index) => (
           <p
           key={index}
           style={
             singleParagraph === "Terms And Conditions"
               ? { fontSize: "30px", fontWeight: "bold", textAlign: "center" }
               : {}
           }
         >
           {singleParagraph}
         </p>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
