const agencydata = [
    {   
        id:1,
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="52" height="47" viewBox="0 0 52 47" fill="none"><path d="M50.1667 23.125H40.3333L32.9583 45.25L18.2083 1L10.8333 23.125H1" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>,
        title: `Data Breach Is More Common Than You Think`,
        text: `1 in 2 American internet users had their accounts breached in 2021`,
    },
    {
        id:2,
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="43" height="47" viewBox="0 0 43 47" fill="none"><path d="M21.175 14.275C32.3173 14.275 41.35 11.3033 41.35 7.6375C41.35 3.97171 32.3173 1 21.175 1C10.0327 1 1 3.97171 1 7.6375C1 11.3033 10.0327 14.275 21.175 14.275Z" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M41.35 23.1251C41.35 26.7978 32.3833 29.7626 21.175 29.7626C9.96667 29.7626 1 26.7978 1 23.1251" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M1 7.63745V38.6125C1 42.2852 9.96667 45.25 21.175 45.25C32.3833 45.25 41.35 42.2852 41.35 38.6125V7.63745" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>,
        title:`US Suffers The Most From Data Breaches`,
        text:`Over A Billion Leaked Records At least 4 2020 breaches involved over a billion records of data.`,     
    },
    {
        id:3,
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none"><path d="M23.5 1L46 15.3813V30.8688L23.5 45.25L1 30.8688V15.3813L23.5 1Z" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M23.5 45.25V30.8688" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M46 15.3812L23.5 30.8687L1 15.3812" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M1 30.8687L23.5 15.3812L46 30.8687" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M23.5 1V15.3813" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>,
        title:` US Organizations aren’t secure either`,
        text:`Every 45 in 100 US organizations experiences data breaches! That's almost half!`,  

    },
    {
        id:4,
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="52" height="47" viewBox="0 0 52 47" fill="none"><path d="M38.8245 2.84875C39.4868 2.26263 40.273 1.79769 41.1384 1.48048C42.0037 1.16327 42.9311 1 43.8677 1C44.8044 1 45.7318 1.16327 46.5971 1.48048C47.4625 1.79769 48.2487 2.26263 48.911 2.84875C49.5733 3.43488 50.0987 4.13072 50.4571 4.89653C50.8155 5.66234 51 6.48313 51 7.31204C51 8.14095 50.8155 8.96175 50.4571 9.72756C50.0987 10.4934 49.5733 11.1892 48.911 11.7753L14.869 41.9025L1 45.25L4.78245 32.976L38.8245 2.84875Z" stroke="#2F4AA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>,
        title:`Thats 1250 every hour!`,
        text:`Globally, an estimated 30,000 websites are hacked each day`,  

    },

]


export { agencydata }