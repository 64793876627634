import React from "react";

const Button = ({ text, handler, width = "small", rounded = "full", type="success", disabled=false }) => {
  return (
    <div
      className={`login-btn-container ${
        width.toLowerCase() === "full" && "login-full-btn-container"
      } 
      ${rounded.toLowerCase() === "medium" && "login-rounded-btn-container"}`}
    >
      <button onClick={handler} type={type} disabled={disabled}>
        {text}
      </button>
    </div>
  );
};

export default Button;
