import axios from "axios";
import { SUPPORT_API } from "../utils/OpenAPI";

const getSupportCategories = async () => {
    try {
        const response = await axios.get(SUPPORT_API.GET_CATEGORIES);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

const submitContactUs = async (data) => {
    try {
        const response = await axios.post(SUPPORT_API.SUBMIT_CONTACT_US, data);
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export {
    getSupportCategories,
    submitContactUs
};