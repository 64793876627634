// Function to return API calling code in JavaScript Language as a Javascript string.
function javaScriptCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = ``;
  if (headers) {
    headersString += `{\n`;
    headersString += Object.keys(headers)
      .map((key) => `  '${key}' = '${headers[key]}'`)
      .join("\n");
    headersString += `\n}`;
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the API call code
  const apiCallCode = `${
    headersString ? `const headers = ${headersString};` : ""
  }${
    payloadRequest
      ? `\n\nlet bodyContent = JSON.stringify(${JSON.stringify(
          payloadRequest,
          null,
          2
        )});`
      : ""
  }
 
let response = await fetch("${endpointWithParams}", { 
method: "${method.toUpperCase()}",
${payloadRequest ? "body: bodyContent,\n  " : ""}${
    headersString ? `headers: headers,` : ""
  }
});
 
let data = await response.text();
console.log(data);`;

  return apiCallCode;
}

// Function to return API calling code in Java Language as a Javascript string.
function javaCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = ``;
  if (headers) {
    headersString += Object.keys(headers)
      .map((key) => `.header("${key}", "${headers[key]}");`)
      .join("\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  const apiCallCode = `HttpRequest request = HttpRequest.newBuilder()
.uri(URI.create("${endpointWithParams}"))${
    headersString ? `\n${headersString}` : ""
  }
.method("${method}", HttpRequest.BodyPublishers.ofString("${JSON.stringify(
    payloadRequest,
    null,
    2
  )}"))
.build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`;

  return apiCallCode;
}

// Function to return API calling code in C# Language as a Javascript string.
function cSharpCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = "";
  if (headers) {
    headersString = Object.keys(headers)
      .map((key) => `request.Headers.Add("${key}", "${headers[key]}");`)
      .join("\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }
  let methodName = "";
  if (method) {
    methodName = method[0].toUpperCase() + method.slice(1).toLowerCase();
  }

  const apiCallCode = `var client = new HttpClient();
var request = new HttpRequestMessage();
request.RequestUri = new Uri("${endpointWithParams}");
request.Method = HttpMethod.${methodName};
${headersString ? `\n${headersString}\n` : ""}
var bodyString = "${JSON.stringify(payloadRequest, null, 2)}";
var content = new StringContent(bodyString, Encoding.UTF8);
request.Content = content;

var response = await client.SendAsync(request);
var result = await response.Content.ReadAsStringAsync();
Console.WriteLine(result);`;

  return apiCallCode;
}

// Function to return API calling code in Go Language as a Javascript string.
function goCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = "";
  if (headers) {
    // Construct the headers string
    headersString = Object.keys(headers)
      .map((key) => `  req.Header.Add("${key}", "${headers[key]}")`)
      .join("\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the API call code
  const apiCallCode = `package main

import (
"fmt"
"strings"
"net/http"
"io/ioutil"
)

func main() {

url := "${endpointWithParams}"

payload := strings.NewReader("${JSON.stringify(payloadRequest, null, 4)}")

req, _ := http.NewRequest("${method}", url, payload)
${headersString ? `\n${headersString}\n` : ""}
res, _ := http.DefaultClient.Do(req)

defer res.Body.Close()
body, _ := ioutil.ReadAll(res.Body)

fmt.Println(res)
fmt.Println(string(body))
}`;

  return apiCallCode;
}

// Function to return API calling code in Python Language as a Javascript string.
function pythonCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = ``;
  if (headers) {
    headersString += `{\n`;
    headersString += Object.keys(headers)
      .map((key) => `  '${key}' = '${headers[key]}'`)
      .join("\n");
    headersString += `\n}`;
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the API call code
  const apiCallCode = `import requests

url = "${endpointWithParams}" ${
    headersString ? `\nheaders = ${headersString}` : ""
  }
payload = "${JSON.stringify(payloadRequest, null, 2)}"

response = requests.${method.toLowerCase()}(url, ${
    headersString ? "headers=headers, " : ""
  }data=payload)

print(response.text)`;

  return apiCallCode;
}

// Function to return API calling code in TypeScript Language as a Javascript string.
function typeScriptCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = "";
  if (headers) {
    // Construct the headers string
    headersString = Object.keys(headers)
      .map((key) => `headers.append("${key}", "${headers[key]}");`)
      .join("\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the API call code
  const apiCallCode = `const apiUrl = "${endpointWithParams}";
const method = "${method}";${
    headersString ? `\nconst headers = new Headers();` : ""
  }
const url = new URL(apiUrl);
${headersString ? `\n${headersString}\n` : ""}
const payload = "${JSON.stringify(payloadRequest, null, 2)}";

const response = await fetch(url.toString(), {
  method: method,${headersString ? `\n    headers: headers,` : ""}
  body: payload ? payload : undefined,
});

const responseData = await response.json();
console.log("API Response:", responseData);`;

  return apiCallCode;
}

// Function to return API calling code in Node Language as a Javascript string.
function nodeCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = ``;
  if (headers) {
    headersString = Object.keys(headers)
      .map((key) => `    '${key}' = '${headers[key]}'`)
      .join("\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the API call code
  const apiCallCode = `const axios = require('axios');
let data = JSON.stringify(${JSON.stringify(payloadRequest, null, 2)};

let config = {
method: '${method.toLowerCase()}',
maxBodyLength: Infinity,
url: '${endpointWithParams}',${
    headersString ? `\n  headers: {\n${headersString}\n  },` : ""
  }
data : data
};

axios.request(config)
.then((response) => {
console.log(JSON.stringify(response.data));
})
.catch((error) => {
console.log(error);
});`;

  return apiCallCode;
}

// Function to return API calling code in cURL Language as a Javascript string.
function curlCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = ``;
  if (headers) {
    // Construct the headers string
    headersString = Object.keys(headers)
      .map((key) => `--header "${key}: ${headers[key]}"`)
      .join("\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    // add params to endpoint
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the cURL command
  const curlCommand = `curl -X ${method} "${endpointWithParams}" \\${
    headersString ? `\n${headersString} \\` : ""
  } 
${payloadRequest ? `--data '${JSON.stringify(payloadRequest, null, 2)}'` : ""}`;

  return curlCommand;
}

// Function to return API calling code in PHP Language as a Javascript string.
function phpCode(endpoint, payloadRequest, method, params, headers) {
  let headersString = "";
  if (headers) {
    // Construct the headers string
    headersString = Object.keys(headers)
      .map((key) => `      "${key}" => "${headers[key]}"`)
      .join(",\n");
  }

  let endpointWithParams = endpoint;
  if (params) {
    // add params to endpoint
    Object.keys(params).forEach((key, i) => {
      endpointWithParams += `${i === 0 ? "?" : "&"}${key}=${params[key]}`;
    });
  }

  // Construct the API call code
  const apiCallCode = `<?php

use Illuminate\Support\Facades\Http;

$response = Http::withBody( 
      '${JSON.stringify(payloadRequest, null, 2)}', 'json' 
  )${headersString ? `\n    ->withHeaders([\n${headersString}\n    ])` : ""} 
  ->${method.toLowerCase()}('${endpointWithParams}'); 

echo $response->body();`;

  return apiCallCode;
}

export {
  javaScriptCode,
  javaCode,
  cSharpCode,
  goCode,
  pythonCode,
  typeScriptCode,
  nodeCode,
  curlCode,
  phpCode,
};
