import React from "react";
import "../styles/Blog.css";

const BlogParentCard = ({ data }) => {
  // create a function to handle the card click
  const handleCardClick = () => {
    localStorage.setItem("blogData", JSON.stringify(data));
    window.location.href = `/BlogChild/${data.id}`;
  };
 
  return (
    <div>
      <section class="blog-parent-cards">
        <div class="blog-card" onClick={handleCardClick}>
          <img
            class="card-img-top"
            src={data.posts[0].ParentImage}
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">{data.title}</h5>
            <p class="card-text">
              {data.posts[0].headerData.text.slice(0, 120)}
            </p>
            <p class="card-text">
              {data.hashtags &&
                data.hashtags.map((tag, index) => (
                  <span key={index} className="hashtag">
                    #{tag}
                    {index < data.hashtags.length - 1 && ' '}
                  </span>
                ))}
            </p>
            <p class="card-text">
              <small class="text-muted">{data.date}</small>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default BlogParentCard;
