import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/api.css";
import { getLanguageCode, parseApi } from "../../utils/ApiServiceEncapsulatorUtils";
import MobileResponsive from "../API/ApiMobileResponsive";
import {SearchBar, ExpendToggleButton, CodeHighlighter, CopyIcon } from "../../components/ApiServiceComponentsExporter";


// Set the path of your content file here.
const apis = require.context("../../content/useCase", true);
const apiList = apis.keys().map((api) => apis(api));

const Scenarios = ({ setIsScenariosDisplayForFooter, user }) => {
  const [fileContent, setFileContent] = useState([]);
  const [apiContent, setApiContent] = useState([]);
  const [filteredApiContent, setFilteredApiContent] = useState([]);
  const [selectedAPi, setSelectedApi] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("java");
  const [response, setResponse] = useState({});
  const [APICode, setAPICode] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [selectedEndPoint , setSelectedEndPoint] = useState({});
  const [search, setSearch] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const navigate = useNavigate();

  let dataNotFetched = true;

  useEffect(() => {
    setIsScenariosDisplayForFooter(true);
    return () => {
      setIsScenariosDisplayForFooter(false);
    };
  }, []);

  useEffect(() => {
    if (dataNotFetched) {
      for (const currApi of apiList) {
        fetch(currApi)
          .then((row) => row.text())
          .then((text) => {
            setFileContent((prev) => [...prev, text]);
          });
      }
      dataNotFetched = false;
    }
  }, []);

  useEffect(() => {
    if (fileContent.length) {
      const myApiContent = fileContent.map((api, index) => {
        const data = parseApi(api);
        data.id = index + 1;
        return data;
      });
      setApiContent(myApiContent);
      setFilteredApiContent(myApiContent);
    }
  }, [fileContent]);

  useEffect(() => {
    if (apiContent.length) {
      setSelectedApi(apiContent[0]);
      setSelectedEndPoint(apiContent[0].endPoints[0]);
    }
  }, [apiContent]);

  useEffect(() => {
    if (fileContent.length) {
      const myApiContent = fileContent.map((api, index) => {
        const data = parseApi(api);
        data.id = index + 1;
        return data;
      });

      setApiContent(myApiContent);
      setFilteredApiContent(myApiContent);
    }
  }, [fileContent]);

  useEffect(() => {
    if (selectedEndPoint) {
      const APIConfig = {
        endpoint: (selectedAPi.baseUrl + selectedEndPoint.endpoint),
        method: selectedEndPoint.method,
      };

      if (selectedEndPoint.payloadRequest) {
        APIConfig.payloadRequest = JSON.parse(selectedEndPoint.payloadRequest);
      }

      for (const key in selectedEndPoint.params) {
        if (selectedEndPoint.params[key] !== "") {
          APIConfig.params = {
            ...APIConfig.params,
            [key]: selectedEndPoint.params[key],
          };
        }
      }

      for (const key in selectedEndPoint.headers) {
        if (selectedEndPoint.headers[key] !== "") {
          APIConfig.headers = {
            ...APIConfig.headers,
            [key]: selectedEndPoint.headers[key],
          };
        }
      }

      const code = getLanguageCode(APIConfig, selectedLanguage);
      setAPICode(code);
    }
  }, [selectedEndPoint, selectedLanguage]);

  const handleSelectApi = (api) => {
    setSelectedApi(api);
    setSelectedEndPoint(api.endPoints[0]);
    setResponse({});
    setIsExpanded(false);
  };

  useEffect(() => {
    if (!selectedAPi.id) {
      return;
    }

    // set headers
    const headers = {};
    for (const key in selectedEndPoint.headers) {
      if (selectedEndPoint.headers[key] !== "") {
        headers[key] = selectedEndPoint.headers[key];
      }
    }

    // set url and optional params
    let url = (selectedAPi.baseUrl + selectedEndPoint.endpoint);
    let isFirstParams = true;
    for (const key in selectedEndPoint.params) {
      if (isFirstParams && selectedEndPoint.params[key] !== "") {
        url += `?${key.toLocaleLowerCase()}=${selectedEndPoint.params[key]}`;
        isFirstParams = false;
      } else {
        if (selectedEndPoint.params[key] !== "") {
          url += `&${key.toLocaleLowerCase()}=${selectedEndPoint.params[key]}`;
        }
      }
    }



    // set request options based on method
    if (selectedEndPoint.method === "GET") {
      fetch(url, {
        method: selectedEndPoint.method,
        headers: headers,
      })
        .then(async (res) => {
            setStatusCode(res.status);
          const data = await res.json();
          setResponse(data);
        })
        .catch((err) => {
          setResponse({
            message: err.message,
          });
        });
    } else if (selectedEndPoint.method === "POST") {
      // set body
      const body = JSON.parse(selectedEndPoint.payloadRequest);

      fetch(url, {
        method: selectedEndPoint.method,
        body: JSON.stringify(body),
        headers: headers,
      })
        .then(async (res) => {
            setStatusCode(res.status);
          const data = await res.json();
          setResponse(data);
        })
        .catch((err) => {
          setResponse({
            message: err.message,
          });
        });
    }
  }, [selectedLanguage, selectedAPi, selectedEndPoint]);

 const CopyAPICodeClick = () => {
    navigator.clipboard.writeText(APICode).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const CopyResponseClick = () => {
    navigator.clipboard.writeText(JSON.stringify(response, null, 2)).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const CopyStaticCodeClick = () => {
    navigator.clipboard.writeText(selectedEndPoint.staticCode).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };


  useEffect(() => {
    // Calculate the height of the content dynamically
    const contentElement = document.querySelector(".left-api-side-panel");
    if (contentElement) {
      setContentHeight(contentElement.clientHeight);
    }
  }, []);


  const handleSearchTitle = (e) => {
    const search = e.target.value;
    // filter apiContent based on search
    const filtered = apiContent.filter((api) => {
      return api.title.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredApiContent(filtered);
    setSearch(search);
  }


  return (
    <>
    {/* controllers and endpoint buttons for mobile view */}
    <MobileResponsive
      search={search}
      handleSearchTitle={handleSearchTitle}
      selectedAPi={selectedAPi}
      selectedEndPoint={selectedEndPoint}
      isExpanded={isExpanded}
      handleSelectApi={handleSelectApi}
      filteredApiContent={filteredApiContent}
    />
    
    {/* main content */}
    <div className="api-main-container">
      <div className="module-container">
        <div className="controller-heading">
          <span>Use Cases</span>
        </div>
        <div className="search-title-container">
          {/* Search bar Component here */}
          <SearchBar value={search} SearchHandler={handleSearchTitle} />

          <div className="title-container">
          {filteredApiContent &&
            filteredApiContent.map((api) => {
              return (
                <div key={api.id}>
                  <p className={`${selectedAPi.id === api.id ? "selected-title" : ""}`} onClick={() => handleSelectApi(api)}>
                    {api.title}
                  </p>
                  {selectedAPi.id === api.id && isExpanded &&
                    <ul className="title-sub-list">
                      <a href="#response"><li>Response</li></a>
                      <a href="#static"><li>{selectedEndPoint.staticCodeTitle}</li></a>
                      {(selectedEndPoint.staticResponses.length > 0) && <a href="#error"><li>Error Types</li></a>}
                    </ul>
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="main-content-container">
        <div className="endpoint-container">
          <div className="endpoint-main-data-container">
          {!user && (
                <div className="blur-layer">
                  <span onClick={() => navigate("/login")}>
                    Please Sign In to view content
                  </span>
                </div>
              )}
            <div class="first-container">
              <div className="description-container">
                <h1>{selectedAPi.title}</h1>
                {selectedEndPoint.description && (selectedEndPoint.description.split('\n')).map((line, i) => {
                  return (
                    <p key={i}>{line}</p>
                  );
                })}
              </div>
              <div className="url-container">
                <div class="complete-url-container">
                  <div class="endpoint-header">
                    <span>Endpoint</span>
                  </div>
                  <div class="endpoint-content">
                    <pre>
                      <CodeHighlighter 
                        code={selectedAPi.baseUrl + selectedEndPoint.endpoint} 
                        language={'javascript'} 
                        showLine={false}
                      />
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            
            {isExpanded && (
              <>
                {/* API code response section */}
                <div className="subheading-container">
                  <hr/>
                  <div class="first-container">
                    <div className="description-container">
                      <h1 id="response">Response</h1>
                      {selectedEndPoint.description && (selectedEndPoint.description.split('\n')).map((line, i) => {
                        return (
                          <p key={i}>{line}</p>
                        );
                      })}
                    </div>
                    <div className="url-container">
                        
                      {/* API Code container */}
                      <div class="complete-url-container-code">
                        <div class="endpoint-header-code">
                          <span>{selectedAPi.title}</span>
                          <div class="languages-container">
                            <div className="dropdown-container">
                              <select
                                className="dropdown"
                                value={selectedLanguage}
                                onChange={(e) => setSelectedLanguage(e.target.value)}
                              >
                                <option value="java">Java</option>
                                <option value="csharp">C#</option>
                                <option value="go">Go</option>
                                <option value="python">Python</option>
                                <option value="javascript">JavaScript</option>
                                <option value="typescript">TypeScript</option>
                                <option value="node">Node</option>
                                <option value="curl">cURL</option>
                                <option value="php">PHP</option>
                              </select>
                            </div>
                            <CopyIcon clickHandler={CopyAPICodeClick} />
                          </div>
                        </div>
                        <div class="endpoint-content">
                          <div className="parent-pre">
                            <pre>
                              <CodeHighlighter code={APICode} language={selectedLanguage} style={"dark"}/>
                            </pre>
                          </div>
                          
                        </div>
                      </div>
                      
                      <div className="responsive-container"></div>
                      
                      {/* Response container */}
                      <div class="complete-url-container">
                        <div class="endpoint-header">
                            <span>Response - {statusCode}</span>
                            <CopyIcon clickHandler={CopyResponseClick} />
                        </div>
                        <div class="endpoint-content">
                            <pre className={`${selectedAPi.title === 'Static Data Retrieval' && "pre-design"}`}>
                              {selectedAPi.title === 'Static Data Retrieval' ? 
                                <>{JSON.stringify(response, null, 2)}</> 
                                :
                                <CodeHighlighter code={JSON.stringify(response, null, 2)} language={'javascript'} />
                              } 
                          </pre>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                
                {/* Static Code section */}
                {selectedEndPoint.staticCode && (
                  <div className="subheading-container">
                    <hr/>
                    <div class="first-container">
                      <div className="description-container">
                        <h1 id="static">{selectedEndPoint.staticCodeTitle}</h1>
                        {selectedEndPoint.staticCodeText && (selectedEndPoint.staticCodeText.split('\n')).map((line, i) => {
                          return (
                            <p key={i}>{line}</p>
                          );
                        })}
                      </div>
                      <div className="url-container">
                        
                        <div class="complete-url-container">
                          <div class="endpoint-header">
                            <span>{selectedEndPoint.staticCodeLanguage}</span>
                            <CopyIcon clickHandler={CopyStaticCodeClick} />
                          </div>
                          <div class="endpoint-content">
                            <pre>
                              <CodeHighlighter code={selectedEndPoint.staticCode} language={selectedEndPoint.staticCodeLanguage.toLowerCase()} />
                            </pre>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>
                )}

                {/* Error Type section */}
                {(selectedEndPoint.staticResponses.length > 0) && (
                    <div className="subheading-container">
                      <hr/>
                      <div class="first-container">
                        <div className="description-container">
                          <h1 id="error">Error Types</h1>
                          {selectedEndPoint.staticCodeText && (selectedEndPoint.staticCodeText.split('\n')).map((line, i) => {
                            return (
                              <p key={i}>{line}</p>
                            );
                          })}
                        </div>
                        <div className="url-container">
                          
                          <div class="complete-url-container">
                            <div class="endpoint-header">
                              <span>Error types</span>
                            </div>
                            <div class="endpoint-content">
                              <div className="endpoint-error-types">
                                {selectedEndPoint.staticResponses && selectedEndPoint.staticResponses.map((response, i) => {
                                  return (
                                    <pre key={i}>{response.header}      {response.paragraph}</pre>
                                  );
                                })}
                              </div>
                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                )}
              </>
            )}

            {/* Expand/collapse toggle button */}
            <ExpendToggleButton
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />

          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Scenarios;
