import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactUsModal from "../pages/FooterPages/ContactUsModal";

const Footer = ({ waitListFeatureFlag }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <footer id="footer">
      <section class="container mx-auto py-2">
        <div class="flex font-poppins">
          {!waitListFeatureFlag && (
            <>
              <div class="text-center">
                <a href="/Research" style={{ color: "whitesmoke" }}>
                  Research
                </a>
              </div>

              <div class="text-center">
                <Link to="/AboutUs" style={{ color: "whitesmoke" }}>
                  About Us
                </Link>
              </div>

              <div class="text-center">
                <Link to="/Blog" style={{ color: "whitesmoke" }}>
                  Blog
                </Link>
              </div>

              <div class="text-center">
                <Link to="/FAQ" style={{ color: "whitesmoke" }}>
                  FAQ
                </Link>
              </div>

              <div class="text-center">
                <Link to="/ContactSupport" style={{ color: "whitesmoke" }}>
                  Contact & Support
                </Link>
              </div>
            </>
          )}

          <div class="text-center">
            <Link to="/legal" style={{ color: "whitesmoke" }}>
              Legal
            </Link>
          </div>

          <div class="text-center">
            <Link to="/privacy" style={{ color: "whitesmoke" }}>
              Privacy Policy
            </Link>
          </div>
        </div>
        <div class="copyrights font-poppins">
          <p
            class="text-center"
            style={{ color: "whitesmoke", marginTop: "5px" }}
          >
            Copyright © 2024 Ciphertea Securities Inc. All Rights Reserved
          </p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
