import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter, PrismLight  } from 'react-syntax-highlighter';
import light from 'react-syntax-highlighter/dist/esm/styles/prism/material-light';
import materialDark from 'react-syntax-highlighter/dist/esm/styles/prism/material-dark';
import { jsx, javascript, typescript, python, csharp, java, php} from 'react-syntax-highlighter/dist/esm/languages/prism';

PrismLight.registerLanguage('jsx', jsx);
PrismLight.registerLanguage('javascript', javascript);
PrismLight.registerLanguage('typescript', typescript);
PrismLight.registerLanguage('python', python);
PrismLight.registerLanguage('csharp', csharp);
PrismLight.registerLanguage('java', java);
PrismLight.registerLanguage('php', php);

const CodeHighlighter = ({ code, language, showLine=true, style="light" }) => {

    const [codeLanguage, setCodeLanguage] = useState(language);

    useEffect(() => {
        if(language === 'node' || language === 'curl') setCodeLanguage('javascript');
        else setCodeLanguage(language);
    }, [language]);
    
    const customStyle = {
        borderRadius: '0 0 8px 8px',
        margin: "0px 0px",
        overflow: 'auto',
        maxHeight: '400px',
        scrollbarWidth: 'thin',
        scrollbarColor: '#c1c1c1 transparent',
        scrollbar: 'width: 6px; height: 6px;',
        scrollbarThumb: 'background: #c1c1c1; border-radius: 4px;',
    };

  return (
    <SyntaxHighlighter 
        language={codeLanguage.toLowerCase()} 
        style={style === 'dark' ? materialDark : light} 
        customStyle={customStyle} 
        showLineNumbers={showLine} 
        wrapLines
    >
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeHighlighter;
