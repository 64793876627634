import React from "react";
import "../../../styles/Modal.css";

const PopopLayout = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="pop-iner-cont">
            <div className="pop-card-cont">
              <div className="">{children}</div>
              <span className="settings-close-btn" onClick={onClose}>
                X
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopopLayout;
