import React from 'react'

const MainContent = ({data}) => {

    return (

        <section class="main-content-area">
            <div class="container mx-auto text-center font-poppins">
                <p class="txt" data-aos='fade-down' data-aos-delay='500'>
                    {data.heading1}
                    </p>
                <div class="area-title">
                    <h1 class="text-lg text-dark font-bold" data-aos='fade-down'data-aos-delay='500'>
                        {data.heading2}
                    </h1>
                </div>
                <div class="grid cols-1 py-5" data-aos="zoom-in-up">
                    <img src="/assests/images/landingpage/problem-solution.png" alt=''></img>
                </div>
            </div>
        </section>

    )
}

export default MainContent
