import { javaScriptCode, javaCode, cSharpCode, goCode, pythonCode, typeScriptCode, nodeCode, curlCode, phpCode } from './ApiIndex.js'; 


function getLanguageCode(apiConfig, language) {
    const { endpoint, payloadRequest, method, params, headers } = apiConfig;
    switch(language){
        case 'javascript':
            return javaScriptCode(endpoint, payloadRequest, method, params, headers);
        case 'java':
            return javaCode(endpoint, payloadRequest, method, params, headers);
        case 'csharp':
            return cSharpCode(endpoint, payloadRequest, method, params, headers);
        case 'go':
            return goCode(endpoint, payloadRequest, method, params, headers);
        case 'python':
            return pythonCode(endpoint, payloadRequest, method, params, headers);
        case 'typescript':
            return typeScriptCode(endpoint, payloadRequest, method, params, headers);
        case 'node':
            return nodeCode(endpoint, payloadRequest, method, params, headers);
        case 'curl':
            return curlCode(endpoint, payloadRequest, method, params, headers);
        case 'php':
            return phpCode(endpoint, payloadRequest, method, params, headers);
        default:
            return null;
    }
}

export default getLanguageCode;