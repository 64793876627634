import React from "react";

const FlipCard = ({ handler, content, Icon }) => {
  return (
    <div className="option-container" onClick={handler}>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <div className="icon-container">
              <Icon />
            </div>
            <h3>{content?.title}</h3>
          </div>
          <div class="flip-card-back">
            <h3>{content?.title}</h3>
            <ul>
              {content?.items?.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
