import React from 'react'
import { Link } from 'react-router-dom'


const WaitList = () => {
  return (
    <div className='wait-list'>
      <h1 className='wait-list-heading'>A Data Security platform enhanced with AI.</h1>
      <Link to="/wait-list/signup"><span style={{color: 'gray'}}>Join The Wait List</span></Link>
    </div>
  )
}

export default WaitList