import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../styles/faq.css";
import Questions from "../../../components/QuestionAndAnswers";
import Tabs from "../../../components/QuestionCategoriesTabs";
import {
  ContactTabs,
  AccountAndProfile,
  BillingAndPayments,
  DataSecurity,
  EverythingElse,
  Integration,
  MachineLearning,
  PrivacyAndSecurity,
  TroubleShouting,
} from "../../../staticData/ContactUs/CategoryAndTabsExporter";

const ContactUs = () => {
  const [selectedTab, setSelectedTab] = useState(ContactTabs[0]);
  const [questions, setQuestions] = useState(AccountAndProfile);

  // update questions based on selected tab
  useEffect(() => {
    switch (selectedTab.title.toLowerCase()) {
      case "account & profile":
        setQuestions(AccountAndProfile);
        break;
      case "integration":
        setQuestions(Integration);
        break;
      case "trouble shouting":
        setQuestions(TroubleShouting);
        break;
      case "billing & payments":
        setQuestions(BillingAndPayments);
        break;
      case "privacy & security":
        setQuestions(PrivacyAndSecurity);
        break;
      case "machine learning":
        setQuestions(MachineLearning);
        break;
      case "data security":
        setQuestions(DataSecurity);
        break;
      case "everything else":
        setQuestions(EverythingElse);
        break;
    }

    // Scroll to the top of the page when the selected tab changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedTab]);

  return (
    <section class="main-faq-section">
      <div className="content-faq-section">
        <div className="faq-title">CipherTEA Support</div>

        {/* Tabs Component */}
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabItems={ContactTabs}
        />

        {/* Question Answer components */}
        <Questions questions={questions} setQuestions={setQuestions} />

        <div className="last-text">
          Still Not Satisfied?{" "}
          <Link to={'/ContactSupport/form'}>
            <span className="last-contact">
              please Fill The Contact Form
            </span>
          </Link>
        </div>
      </div>

    </section>
  );
};

export default ContactUs;
