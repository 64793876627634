import React from "react";
import { useState, useEffect } from "react";
import "../../styles/Docs.css";
import DocumentFileParser from "../../utils/DocsParserUtil";

const documents = require.context("../../content/DocsContent", true);
const documentList = documents.keys().map((document) => documents(document));

const Docs = ({ setIsDocsDisplayForFooter }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fileContent, setFileContent] = useState([]);
  const [documentContent, setDocumentContent] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [filteredDocumentContent, setFilteredDocumentContent] = useState([]);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [isopen, setIsopen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setIsDocsDisplayForFooter(true);
    return () => {
      setIsDocsDisplayForFooter(false);
    }
  }, []);

  let check = true;
  useEffect(() => {
    if (check) {
      for (const currDocument of documentList) {
        fetch(currDocument)
          .then((row) => row.text())
          .then((text) => {
            setFileContent((prev) => [...prev, text]);
          });
      }
      check = false;
    }
  }, []);

  useEffect(() => {
    // Filter side panel items based on searchQuery
    const filteredItems = documentContent.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDocumentContent(filteredItems);
  }, [searchQuery, documentContent]);

  useEffect(() => {
    if (fileContent.length) {
      const myDocumentContent = fileContent.map((document, index) => {
        const data = DocumentFileParser(document);
        data.id = index + 1;
        return data;
      });
      const sortedDocumentContent = [...myDocumentContent].sort(
        (a, b) => a.id - b.id
      );
      setDocumentContent(sortedDocumentContent);
      setSelectedDocument(sortedDocumentContent[0]);
      setFilteredDocumentContent(sortedDocumentContent);
    }
  }, [fileContent]);

  //pagination
  useEffect(() => {
    if (fileContent.length) {
      const myDocumentContent = fileContent.map((document, index) => {
        const data = DocumentFileParser(document);
        data.id = index + 1;
        return data;
      });
      setDocumentContent(myDocumentContent);
      setSelectedDocument(myDocumentContent[selectedDocumentIndex]);
      setFilteredDocumentContent(myDocumentContent);
    }
  }, [fileContent, selectedDocumentIndex]);

  useEffect(() => {
    // Filter side panel items based on searchQuery
    const filteredItems = documentContent.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDocumentContent(filteredItems);
  }, [searchQuery, documentContent]);

  const getTitleForNext = () => {
    const nextIndex =
      (selectedDocumentIndex + 1) % filteredDocumentContent.length;
    return filteredDocumentContent[nextIndex]?.title;
  };

  const getTitleForPrevious = () => {
    const previousIndex =
      selectedDocumentIndex === 0
        ? filteredDocumentContent.length - 1
        : selectedDocumentIndex - 1;
    return filteredDocumentContent[previousIndex]?.title;
  };

  const handleNext = () => {
    setSelectedDocumentIndex(
      (prevIndex) => (prevIndex + 1) % filteredDocumentContent.length
    );
  };

  const handlePrevious = () => {
    setSelectedDocumentIndex((prevIndex) =>
      prevIndex === 0 ? filteredDocumentContent.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    // Calculate the height of the content dynamically
    const contentElement = document.querySelector(".left-side-panel-desktop");
    if (contentElement) {
      setContentHeight(contentElement.clientHeight);
    }
  }, [filteredDocumentContent]);

  return (
    <section class="flex main-docs-section">
      <div class="left-side-panel left-side-panel-desktop">
        <p class="pages">Pages</p>
        <div class="side-searchbar">
          <input
            placeholder="Search...."
            id="searchBar"
            name="searchBar"
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          ></input>
          <svg
            class="glass-icon"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
          </svg>
        </div>
        {filteredDocumentContent?.map((document) => {
          return (
            <p
              className={`side-title ${
                selectedDocument === document ? "selected" : ""
              }`}
              style={{ cursor: "pointer" }}
              key={document.id}
              onClick={() => setSelectedDocument(document)}
            >
              {document.title}
            </p>
          );
        })}
      </div>

      <div class="v-line" style={{ height: `${contentHeight}px` }}></div>

      
      {/* Mobile Left Side Panel */}
      <div className="left-panel-toggle-btn" onClick={() => setIsopen(!isopen)}>
        Pages
      </div>

      {isopen && (
        <div class="left-side-panel left-side-panel-mobile">
          <div class="side-searchbar">
            <input
              placeholder="Search...."
              id="searchBar"
              name="searchBar"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            ></input>
            <svg
              class="glass-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>
          </div>
          {filteredDocumentContent?.map((document) => {
            return (
              <p
                className={`side-title ${
                  selectedDocument === document ? "selected" : ""
                }`}
                style={{ cursor: "pointer" }}
                key={document.id}
                onClick={() => setSelectedDocument(document)}
              >
                {document.title}
              </p>
            );
          })}
        </div>
      )}

      <div class="middle-content">
        <h1 class="title">{selectedDocument?.title}</h1>
        <p class="first-text">{selectedDocument?.firstText}</p>
        <hr/>

        <div class="two-sections">
          <div class="left-div">
            <p class="text">{selectedDocument?.text}</p>

            <div class="content">
              {selectedDocument?.headings_1?.map((heading1, index) => {
                return (
                  <React.Fragment key={index}>
                    <h1 id={`heading${index}`}>{heading1.title}</h1>
                    <span class="caption">{heading1.caption}</span>
                    <img src={heading1.image} />
                    <ul>
                      {heading1.bullets.map((bullet, bulletIndex) => {
                        return (
                          <li key={bulletIndex} class="circle-bullet">
                            {bullet.text}
                            <ul>
                              {bullet.sub_bullets.map(
                                (subBullet, subBulletIndex) => {
                                  return (
                                    <li
                                      key={subBulletIndex}
                                      class="circle-subbullet"
                                    >
                                      {subBullet.text}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                    <ol class="numbered">
                      {heading1.numbers_1.map((number1, number1Index) => {
                        return (
                          <li key={number1Index} class="numbered-bullet">
                            {number1.text}
                          </li>
                        );
                      })}
                    </ol>
                    {heading1.headings_2.map((heading2, heading2Index) => {
                      return (
                        <React.Fragment key={heading2Index}>
                          <h2 id={`heading${index}-${heading2Index}`}>
                            {heading2.title}
                          </h2>
                          <span class="description">
                            {heading2.description}
                          </span>
                          <img src={heading2.image} />
                          <ul>
                            {heading2.bullets.map((bullet, bulletIndex) => {
                              return (
                                <li key={bulletIndex} class="circle-bullet">
                                  {bullet.text}
                                  <ul>
                                    {bullet.sub_bullets.map(
                                      (subBullet, subBulletIndex) => {
                                        return (
                                          <li
                                            key={subBulletIndex}
                                            class="circle-subbullet"
                                          >
                                            {subBullet.text}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </li>
                              );
                            })}
                          </ul>
                          <ol class="numbered">
                            {heading2.numbers_1.map((number1, number1Index) => {
                              return (
                                <li key={number1Index} class="numbered-bullet">
                                  {number1.text}
                                </li>
                              );
                            })}
                          </ol>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div class="right-div">
            <svg
              viewBox="0 0 1024 1024"
              class="icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              stroke="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M941.26 234.723H328.964c-28.867 0-52.263 23.4-52.263 52.268v3.734c0 28.868 23.396 52.269 52.263 52.269H941.26c28.869 0 52.269-23.401 52.269-52.269v-3.734c-0.001-28.868-23.4-52.268-52.269-52.268z"
                  fill="F0D043"
                ></path>
                <path
                  d="M941.26 682.74H328.964c-28.867 0-52.263 23.399-52.263 52.268v3.734c0 28.863 23.396 52.269 52.263 52.269H941.26c28.869 0 52.269-23.405 52.269-52.269v-3.734c-0.001-28.868-23.4-52.268-52.269-52.268z"
                  fill="4A5699"
                ></path>
                <path
                  d="M709.781 458.729H328.964c-28.867 0-52.263 23.4-52.263 52.269v3.734c0 28.873 23.396 52.269 52.263 52.269h380.817c28.866 0 52.271-23.396 52.271-52.269v-3.734c0.001-28.869-23.405-52.269-52.271-52.269z"
                  fill=""
                ></path>
              </g>
            </svg>
            <div className="table-of-contents">
              <h5>Table of Contents</h5>
              <ul>
                {selectedDocument?.headings_1?.map((heading1, index) => {
                  return (
                    <li key={index}>
                      <a href={`#heading${index}`}>{heading1.title}</a>
                      <ul>
                        {heading1.headings_2.map((heading2, idx) => {
                          return (
                            <li key={idx}>
                              <a href={`#heading${index}-${idx}`}>
                                {heading2.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="pagination">
          <button
            className="previous-btn"
            onClick={handlePrevious}
            style={{ display: selectedDocumentIndex === 0 ? "none" : "flex" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="currentColor"
              class="bi bi-arrow-left-circle"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
              />
            </svg>
            {`${getTitleForPrevious()}`}
          </button>
          <button
            className="next-btn"
            onClick={handleNext}
            style={{
              display:
                selectedDocumentIndex === filteredDocumentContent.length - 1
                  ? "none"
                  : "flex",
            }}
          >
            {`${getTitleForNext()}`}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="currentColor"
              class="bi bi-arrow-right-circle"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Docs;
