const LegalData = {
    privacypolicy: {
      heading: `Privacy Policy`,
      content:`Welcome to CipherTEA. We are committed to protecting your privacy and handling your
      personal information responsibly. This Privacy Policy explains how CipherTEA collects, uses,
      and shares your information when you use our website and services.`,
    },
    personalinformation: {
      heading: `Use of Your Personal Information`,
      content: `CipherTEA collects and uses your personal information to operate our website and deliver the
      services you have requested. CipherTEA may also use your personal information to inform you
      of other products or services available from CipherTEA. We may also contact you via surveys to
      conduct research about your opinion of current services or potential new services we may offer. <br /><br />` +
      `CipherTEA does not sell, rent, or lease its customer lists to third parties. However, we may share
      data with trusted third parties to help perform statistical analysis, send you email or postal mail,
      provide customer support. These third parties are prohibited from using your personal
      information except to provide these services to CipherTEA, and they must maintain the
      confidentiality of your information.<br /><br />` +
     ` CipherTEA may disclose your personal information without notice only if required to do so by
      law or if we believe it is necessary to: (a) comply with legal obligations or respond to legal
      process; (b) protect and defend the rights or property of CipherTEA; or (c) act under exigent
      circumstances to protect the personal safety of CipherTEA users or the public`,
    },
    collectedinformation: {
      heading: `Automatically Collected Information`,
      content: `CipherTEA may automatically collect information about your computer hardware and software
      when you visit our website. This information may include your IP address, browser type, domain
      names, access times, and referring website addresses. This information is used for the operation
      of the service, to maintain quality of the service, and to provide general statistics regarding use of
      the CipherTEA website.`,
    },
    cookies: {
      heading: `What Are Cookies?`,
      content: `A cookie is a small text file placed on your hard disk by a web page server. Cookies can be used
      to uniquely identify your browser or store information in your browser. They cannot be used to
      run programs or deliver viruses to your computer. "Web beacons" link web pages to servers and
      may be used to transmit information collected through cookies back to a web server.
      CipherTEA uses both first-party cookies (set by our website) and third-party cookies (set by
      servers outside our domain). If you want to learn more about these practices or manage your
      choices about not having this information collected and shared with third parties, visit
      networkadvertising.org or review our "Managing Your Cookies" section below.`,
    },
    cookiestypes: {
      heading: `Types of Cookies We Use`,
      content: `• Essential Cookies: These cookies are necessary for CipherTEA's website to operate properly. For example, we use cookies to authenticate you when you log in to our platform.<br /><br />` + 
      `• Personalization/Customization Cookies: These cookies allow us to remember the choices you make on our website and store your preferences, improving your user experience.<br /><br />` +
      `• Analytics Cookies: We use analytics to collect information about your use of our website to create reports and statistics on the website's performance.<br /><br />` +
      `• Advertising Cookies: We may use advertising cookies to market CipherTEA products or services to you on third-party websites.`,
    },
    cancellation: {
      heading: `Service Cancellation and Rejection`,
      content: `CipherTEA reserves the right to cancel or reject services with any provider or client without
      reason. This may be done at our sole discretion and may occur at any time.`,
    },
    datausage: {
      heading: `Data Usage`,
      content: `CipherTEA does not use company data for advertising or sales purposes. We prioritize data
      privacy and ensure your information is not shared or utilized for any marketing activities.`,
    },
    managecookies: {
      heading: `Managing Your Cookies`,
      content: `You can choose to accept or decline cookies. Most desktop browsers automatically accept
      cookies, but you can modify your browser settings to decline them. Mobile devices allow you to
      control cookies through their system settings. Refer to your device manufacturer’s instructions
      for more information on how to do this. If you choose to decline cookies, you may not be able to
      fully experience CipherTEA's interactive features.`,
    },
    securityinformation: {
      heading: `Security of Your Personal Information`,
      content: `CipherTEA secures your personal information from unauthorized access, use, or disclosure. We
      use SSL (Secure Sockets Layer) protocol for encryption when transmitting personal information
      to other websites.`,
    },
    childrenunderthirteen: {
      heading: `Children Under Thirteen`,
      content: `CipherTEA does not knowingly collect personally identifiable information from children under
      the age of thirteen.`,
    },
    changestatement: {
      heading: `Changes to This Statement`,
      content: `CipherTEA may update this Privacy Policy to reflect company and customer feedback. We
      encourage you to periodically review this policy to stay informed about how we protect your
      information.`,
    },
    contactus: {
      heading: `Contact Us`,
      content: `If you have any questions about these Terms, please contact us via Contact Us Form.
      This document was last updated on 04/27/24`,
    },
  };
  
  export default LegalData;