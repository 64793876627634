import React, { useEffect } from 'react'
import '../../styles/legal.css'
import PrivacyData from '../../staticData/PrivacyData';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='privacy-container'>
        <div className='privacy-header'>
            Privacy Policy
        </div>
        <div className='privacy-content-container'>
        {Object.entries(PrivacyData).map(([key, value]) => (
          <div key={key} className='p-container'>
            <div className='p-heading'>{value.heading}</div>
            <p className='p-content' dangerouslySetInnerHTML={{ __html: value.content }} />          </div>
        ))}
        </div>
    </div>
  )
}

export default Privacy