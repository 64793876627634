import React from 'react'
import '../../styles/waitlistSignUp.css'
import Form from './WaitlistSignUpForm';

const WaitListSignUp = () => {
  return (
    <div class="main-container">
      <div className='login-header-container'>
        Join The Wait List
      </div>
      <div class="login-container">
        <div class="extra-container">
          <div class="header-container">
            <span>Fill Out The Form</span>
            <p>Get notified for all major updates for our platform!</p>
          </div>
            <Form/>
        </div>
      </div>
    </div>
  )
}

export default WaitListSignUp