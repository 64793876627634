const LegalData = {
    introduction: {
      heading: `Introduction`,
      content:`These Terms of Service ("Terms") govern your use of our software and related services
      ("Service"). By using our Service, you agree to these Terms. The Site is offered to you
      conditioned on your acceptance without modification of the terms, conditions, and notices
      contained herein (the "Terms"). Please read these terms carefully and keep a copy of them for
      your reference. All purchases are final and non-refundable. Please read them carefully`,
    },
    account: {
      heading: `Your Account`,
      content: `For the purposes of these Terms and Conditions, unless otherwise noted, all references to “your
      account” mean / include your right to access and to use this site and your usage of this site. If
      you use this site, you are responsible for maintaining the confidentiality of your account and for
      restricting access to your computer, and you agree to accept responsibility for all activities that
      occur under your account. You may not assign or otherwise transfer your account to any other
      person or entity, and you may not share an account with another person. You acknowledge that
      CipherTEA is not responsible for third party access to your account that results from theft or
      misappropriation of your account. CipherTEA reserves the right to refuse or cancel service,
      terminate accounts, or remove or edit content`,
    },
    children: {
      heading: `Children under Thirteen`,
      content: `CipherTEA does not knowingly collect, either online or offline, personal information from
      persons under the age of thirteen.`,
    },
    youracceptance: {
      heading: `Your Acceptance`,
      content: `By accessing and using our Service, you acknowledge that you have read, understood, and agree
      to be bound by these Terms. If you do not agree with any part of these Terms, you must not use
      our Service.`,
    },
    license: {
      heading: `License`,
      content: `We grant you a limited, non-exclusive, non-transferable, and revocable license to use our Service
      for your personal or internal business purposes in accordance with these Terms.`,
    },
    intellectual: {
      heading: `Intellectual Property`,
      content: `All rights, title, and interest in and to our Service, including all intellectual property rights, are
      and will remain the exclusive property of CipherTEA`,
    },
    datasecurity: {
      heading: `Data Security`,
      content: `We are committed to protecting your data and ensuring its security. We employ industry-
      standard security measures to safeguard your information from unauthorized access, disclosure,
      alteration, or destruction.`,
    },
    datausage: {
      heading: `Data Usage`,
      content: `CipherTEA does not use your data for any sales, advertisement, marketing or any other purpose.
      Additionally, CipherTEA does not own any data that you utilize through the platform. While
      CipherTEA provides a platform designed to protect data integrity, we are not responsible for any
      data breaches to the system. Our responsibility is limited to safeguarding data integrity on our
      platform and not on the platforms where your data may reside.`,
    },
    userresponsibility: {
      heading: `User Responsibilities`,
      content: `You are responsible for maintaining the confidentiality of your account credentials and for all
      activities that occur under your account. You agree to notify us immediately of any unauthorized
      use of your account or any other breach of security.`,
    },
    privacypolicy: {
      heading: `Privacy Policy`,
      content: `Please refer to our Privacy Policy for information on how we collect, use, and disclose data
      collected from you when you use our Service.`,
    },
    termination: {
      heading: `Termination/Access Restriction`,
      content: `CipherTEA reserves the sole discretion to terminate your access to the Site and its related
      services, or any part thereof, at any time and without prior notice.`,
    },
    internationalusers: {
      heading: `International Users`,
      content: `The Service is controlled, operated, and administered by CipherTEA from our offices within the
      USA. If you access the Service from a location outside the USA, you are responsible for
      compliance with all local laws. You agree that you will not use the CipherTEA Content accessed
      through the Site in any country or in any manner prohibited by any applicable laws, restrictions,
      or regulations.`,
    },
    limitation: {
      heading: `Limitation of Liability`,
      content: `In no event shall CipherTEA, its affiliates, or its licensors be liable for any indirect, incidental,
      special, consequential, or punitive damages, including but not limited to loss of profits, data, or
      use, arising out of or in connection with your use of our Service.`,
    },
    governing: {
      heading: `Governing Law`,
      content: `These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction],
      without regard to its conflict of law provisions.`,
    },
    changestoterms: {
      heading: `Changes to Terms`,
      content: `CipherTEA reserves the right, at its sole discretion, to change the terms under which the Site is
      offered. The most current version of the terms will replace all previous versions. CipherTEA
      encourages you to periodically review the terms to stay informed of any updates.`,
    },
    contactus: {
      heading: `Contact Us`,
      content: `If you have any questions about these Terms, please contact us via Contact Us Form.
      This document was last updated on 04/27/24`,
    },
  };
  
  export default LegalData;