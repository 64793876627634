import { CRYPTOGRAPHY_CORE_LIB_DOMAIN_URL } from "../utils/OpenAPI";

function parseApi(apiData) {
  const parsedApi = {
    title: "",
    baseUrl: "",
    endPoints: [],
  };

  const lines = apiData.split("\n");

  let isEndPoint = false;
  let headersCheck = false;
  let paramsCheck = false;
  let staticResponseCheck = false;
  let isDescription = false;
  let insideCodeTag = false;
  let staticCodeCheck = false;

  let staticResponse = {};

  lines.forEach((line) => {
    const match = line.match(/<([^>]+)>([^<]*)<\/\1>/);

    if (match) {
      const [, tag, content] = match;

      if (isEndPoint) {
        if (headersCheck) {
          parsedApi.endPoints[parsedApi.endPoints.length - 1].headers[tag] =
            content;
        } else if (paramsCheck) {
          parsedApi.endPoints[parsedApi.endPoints.length - 1].params[tag] =
            content;
        } else if (staticResponseCheck) {
          switch (tag.toLowerCase()) {
            case "title":
              staticResponse.title = content;
              break;
            case "header":
              staticResponse.header = content;
              break;
            case "paragraph":
              staticResponse.paragraph = content;
              break;
            default:
              staticResponse[tag] = content;
              break;
          }
        } else {
          switch (tag.toLowerCase()) {
            case "text":
              parsedApi.endPoints[parsedApi.endPoints.length - 1].description =
                content;
              break;
            case "endpoint":
              parsedApi.endPoints[parsedApi.endPoints.length - 1].endpoint =
                content;
              break;
            case "method":
              parsedApi.endPoints[parsedApi.endPoints.length - 1].method =
                content.toUpperCase();
              break;
            case "staticcodetitle":
              parsedApi.endPoints[
                parsedApi.endPoints.length - 1
              ].staticCodeTitle = content;
              break;
            case "staticcodetext":
              parsedApi.endPoints[
                parsedApi.endPoints.length - 1
              ].staticCodeText = content;
              break;
            case "staticcodelanguage":
              parsedApi.endPoints[
                parsedApi.endPoints.length - 1
              ].staticCodeLanguage = content;
              break;
            default:
              parsedApi.endPoints[parsedApi.endPoints.length - 1][tag] = content;
              break;
          }
        }
      } else {
        switch (tag.toLowerCase()) {
          case "title":
            parsedApi.title = content;
            break;
          case "baseurl":
            parsedApi.baseUrl =CRYPTOGRAPHY_CORE_LIB_DOMAIN_URL+ content;
            break;
          default:
            parsedApi[tag] = content;
            break;
        }
      }
    }

    if (match === null) {
      const singleMatch = line.match(/<([^>]+)>/);
      if (singleMatch) {
        const [, tag] = singleMatch;

        if (tag.toLowerCase() === "endpoint") {
          isEndPoint = true;
          parsedApi.endPoints.push({
            description: "",
            endpoint: "",
            payloadRequest: "",
            method: "",
            params: {},
            headers: {},
            staticCodeTitle: "",
            staticCodeText: "",
            staticCodeLanguage: "",
            staticCode: "",
            staticResponses: [],
          });
        } else if (tag.toLowerCase() === "/endpoint") {
          isEndPoint = false;
        } else if (tag.toLowerCase() === "headers") {
          headersCheck = true;
        } else if (tag.toLowerCase() === "/headers") {
          headersCheck = false;
        } else if (tag.toLowerCase() === "parameters") {
          paramsCheck = true;
        } else if (tag.toLowerCase() === "/parameters") {
          paramsCheck = false;
        } else if (tag.toLowerCase() === "staticresponse") {
          staticResponseCheck = true;
        } else if (tag.toLowerCase() === "/staticresponse") {
          staticResponseCheck = false;
          parsedApi.endPoints[
            parsedApi.endPoints.length - 1
          ].staticResponses.push(staticResponse);
          staticResponse = {};
        } else if (tag.toLowerCase() === "payloadrequest") {
          insideCodeTag = true;
        } else if (tag.toLowerCase() === "/payloadrequest") {
          insideCodeTag = false;
        } else if (tag.toLowerCase() === "staticcode") {
          staticCodeCheck = true;
        } else if (tag.toLowerCase() === "/staticcode") {
          staticCodeCheck = false;
        } else if (isEndPoint && tag.toLowerCase() === "text") {
          isDescription = true;
        } else if (isEndPoint && tag.toLowerCase() === "/text") {
          isDescription = false;
        }
      } else {
        if (insideCodeTag) {
          parsedApi.endPoints[parsedApi.endPoints.length - 1].payloadRequest +=
            line + "\n";
        } else if (staticCodeCheck) {
          parsedApi.endPoints[parsedApi.endPoints.length - 1].staticCode +=
            line + "\n";
        } else if (isDescription) {
          parsedApi.endPoints[parsedApi.endPoints.length - 1].description +=
            line + "\n";
        }
      }
    }
  });

  return parsedApi;
}

export default parseApi;