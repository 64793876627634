import "./App.css";
// import aos
import Aos from "aos";
// import aos css
import "aos/dist/aos.css";
import Index from "./pages/Index";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ScrollToTop from "react-scroll-to-top";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { waitListFeatureFlag } from "./staticData/waitList";
import { useState, useEffect } from "react";
import { getFlag } from "./services/OpenAPI";

// Import pages
import Api from "./pages/API/Api";
import Docs from "./pages/Doc/Docs";
import SignUp from "./pages/Login/SignUp";
import Login from "./pages/Login/Login";
import Research from "./pages/FooterPages/Research";
import ResearchChild from "./pages/FooterPages/ResearchChild";
import Pricing from "./pages/Login/Pricing";
import AboutUs from "./pages/FooterPages/About/AboutUs";
import Blog from "./pages/FooterPages/Blog/Blog";
import BlogChild from "./pages/FooterPages/Blog/BlogChild";
import FAQ from "./pages/FooterPages/FAQs/Faq";
import { Helmet } from "react-helmet";
import Scenarios from "./pages/Scenarios/Scenarios";
import Support from "./pages/FooterPages/ContactUs/Support";
import ContactForm from "./pages/FooterPages/ContactUs/ContactForm";
import Legal from "./pages/FooterPages/Legal";
import Privacy from "./pages/FooterPages/Privacy";
import WaitList from "./pages/WaitList/WaitList";
import WaitListSignUp from "./pages/WaitListSignUp/WaitListSignUp";
import Settings from "./pages/Settings/Settings";

// THIS IS THE STARTING INDEX PAGE OF THE APPLICATION

function App() {
  // initialize aos
  Aos.init({
    duration: 1800,
    offset: 100,
  });
  const [waitListFeatureFlag, setwaitListFeatureFlag] = useState(true);
  const [isScenariosDisplayForFooter, setIsScenariosDisplayForFooter] = useState(false);
  const [isDocsDisplayForFooter, setIsDocsDisplayForFooter] = useState(false);
  const [isApiDisplayForFooter, setIsApiDisplayForFooter] = useState(false);
  const [user, setUser] = useState(sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null);

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if(process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "Development" || process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "local") {
      setwaitListFeatureFlag(process.env.REACT_APP_WAIT_LIST_FLAG === "true" ? true : false);
    }
    else {
      try {
        getFlag("wait-list-flag").then((response) => {
          setwaitListFeatureFlag(response.data.value);
        });  
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  
  return (
    <Router>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>CipherTEA</title>
          <meta name="description" content="CipherTea.com" />
        </Helmet>
        <body>
          <div id="container">
            <Navbar 
              waitListFeatureFlag={waitListFeatureFlag} 
              user={user}
              setUser={setUser}
            />
            <Routes>

              {/* waitListFeatureFlag: true */}
              {waitListFeatureFlag && (
                <>
                  <Route path="/" element={<WaitList/>}/>
                  <Route path="/wait-list" element={<WaitList/>}/>
                  <Route path="/wait-list/signup" element={<WaitListSignUp/>}/>
                  <Route path="/legal" element={<Legal />} />
                  <Route path="/privacy" element={<Privacy />} />
                </>
              )}

              {/* waitListFeatureFlag: false */}
              {!waitListFeatureFlag && (
                <>
                  <Route path="/" element={<Index />} />
                  <Route path="/Docs" element={<Docs setIsDocsDisplayForFooter={setIsDocsDisplayForFooter} user={user} />} />
                  <Route path="/scenarios" element={<Scenarios setIsScenariosDisplayForFooter={setIsScenariosDisplayForFooter} user={user} />} />
                  <Route path="Api" element={<Api setIsApiDisplayForFooter={setIsApiDisplayForFooter} user={user} />} />
                  <Route path="/Research" element={<Research />} />
                  <Route path="/ResearchChild/:id" element={<ResearchChild />}/>
                  <Route path="/SignUp" element={<SignUp />} />
                  <Route path="/Login" element={<Login setUser={setUser}/>} />
                  <Route path="/Pricing" element={<Pricing />} />
                  <Route path="/AboutUs" element={<AboutUs />} />
                  <Route path="/Blog" element={<Blog />} />
                  <Route path="/BlogChild/:id" element={<BlogChild />} />
                  <Route path="/FAQ" element={<FAQ />} />
                  <Route path="/ContactSupport" element={<Support />} />
                  <Route path="/ContactSupport/form" element={<ContactForm user={user} />} />
                  <Route path="/legal" element={<Legal />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="settings" element={<Settings user={user}/>} />
                </>
              )}

            </Routes>
          </div>
          {!(isScenariosDisplayForFooter || isDocsDisplayForFooter || isApiDisplayForFooter) && (
            <Footer waitListFeatureFlag={waitListFeatureFlag} />
          )}
          <ScrollToTop smooth />
        </body>
      </div>
    </Router>
  );
}

export default App;
