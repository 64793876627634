import React from 'react'
import { Link } from 'react-router-dom'
import "../../styles/pricing.css"


const Pricing = () => {
  return (
    <div className='pricing'>
      <h1 className='pricing-heading'>This is currently the beta version of the product. Please enjoy the free version for the next few months.</h1>
    </div>
  )
}

export default Pricing