import React, { useState } from "react";

const EmailVerifyForm = ({ onClose, setEmailVerified }) => {
  const [code, setCode] = useState("");

  const handleSubmit = () => {
    setEmailVerified(true);
    onClose();
  };

  return (
    <div className="remove-pop-main-cont">
      <div className="pop-heading">
        <h1>Verify Email</h1>
      </div>
      <h3>
        A code has been sent to your email. <br />Please enter the code below your
        account?
      </h3>
      <div className="delete-input">
        <input
          name="code"
          placeholder="Enter Code"
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>
      <button className="add-pop-btn" onClick={handleSubmit}>
        Verify
      </button>
    </div>
  );
};

export default EmailVerifyForm;
