import React from 'react'

const SvgLinks = ({ link }) => {
    return (
        <div class="arrow-up">
            <a href={link} target="_blank">
                <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g id="Arrow / Arrow_Up_Right_LG">
                            {" "}
                            <path
                                id="Vector"
                                d="M18.3646 5.63623H11.2939M18.3646 5.63623L18.3643 12.7073M18.3646 5.63623L5.63672 18.3642"
                                stroke="#000000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>{" "}
                        </g>{" "}
                    </g>
                </svg>
            </a>

        </div>
    )
}

export default SvgLinks