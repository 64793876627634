import React from 'react'

const TextCard = ({ data }) => {
  return (
    <div class="mx-auto" data-aos="zoom-in-up">
        <div class="aboutus-box">
          <h2 class="story">{data.title}</h2>
          <p>{data.para1}</p>
          <p>{data.para2}</p>
        </div>
    </div>
  )
}

export default TextCard