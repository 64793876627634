function DocumentFileParser(fileData) {
  const doc = {
    title: "",
    firstText: "",
    text: "",
    parent_image: "",
    headings_1: [],
  };

  const lines = fileData.split("\n");
  let currentNumberedContent = null;

  // Iterate through each line
  lines.forEach((line) => {
    const match = line.match(/<([^>]+)>([^<]*)<\/\1>/);

    if (match) {
      const [, tag, content] = match;

      // Handle tags
      switch (tag.toLowerCase()) {
        case "title":
          doc.title = content;
          break;
        case "firsttext":
          doc.firstText = content;
          break;
        case "text":
          doc.text = content;
          break;
        case "parentimage":
          doc.parent_image = content;
          doc.headings_1[doc.headings_1.length - 1].image = content;
          break;
        case "header1":
          doc.headings_1.push({
            title: content,
            bullets: [],
            headings_2: [],
            numbers_1: [],
          });
          break;
        case "caption":
          doc.headings_1[doc.headings_1.length - 1].caption = content;
          break;
        case "bullet":
          if (doc.headings_1[doc.headings_1.length - 1].headings_2.length > 0) {
            doc.headings_1[doc.headings_1.length - 1].headings_2[
              doc.headings_1[doc.headings_1.length - 1].headings_2.length - 1
            ].bullets.push({
              text: content,
              sub_bullets: [],
            });
          } else {
            doc.headings_1[doc.headings_1.length - 1].bullets.push({
              text: content,
              sub_bullets: [],
            });
          }
          break;
        case "header2":
          doc.headings_1[doc.headings_1.length - 1].headings_2.push({
            title: content,
            bullets: [],
            numbers_1: [],
          });
          break;
        case "description":
          doc.headings_1[doc.headings_1.length - 1].headings_2[
            doc.headings_1[doc.headings_1.length - 1].headings_2.length - 1
          ].description = content;
          break;
        case "image":
          if (doc.headings_1[doc.headings_1.length - 1].headings_2.length > 0) {
            doc.headings_1[doc.headings_1.length - 1].headings_2[
              doc.headings_1[doc.headings_1.length - 1].headings_2.length - 1
            ].image = content;
          } else {
            doc.headings_1[doc.headings_1.length - 1].image = content;
          }
          break;
        case "subbullet":
          const headings_1_length = doc.headings_1.length;
          const headings_2_length =
            doc.headings_1[headings_1_length - 1].headings_2.length;
          const headings_1_bullets_length =
            doc.headings_1[headings_1_length - 1].bullets.length;

          if (headings_2_length > 0) {
            const headings_2_bullets_length =
              doc.headings_1[headings_1_length - 1].headings_2[
                headings_2_length - 1
              ].bullets.length;
            doc.headings_1[headings_1_length - 1].headings_2[
              headings_2_length - 1
            ].bullets[headings_2_bullets_length - 1].sub_bullets.push({
              text: content,
            });
          } else {
            doc.headings_1[headings_1_length - 1].bullets[
              headings_1_bullets_length - 1
            ].sub_bullets.push({
              text: content,
            });
          }
          break;
        case "#":
          if (doc.headings_1[doc.headings_1.length - 1].headings_2.length > 0) {
            doc.headings_1[doc.headings_1.length - 1].headings_2[
              doc.headings_1[doc.headings_1.length - 1].headings_2.length - 1
            ].numbers_1.push({
              text: content,
              numbers_2: [],
            });
          } else {
            doc.headings_1[doc.headings_1.length - 1].numbers_1.push({
              text: content,
              numbers_2: [],
            });
          }
          break;
        case "##":
          const headings__1_length = doc.headings_1.length;
          const headings__2_length = doc.headings_1[headings__1_length - 1].headings_2.length;
          const headings__1_numbers_1_length = doc.headings_1[headings__1_length - 1].numbers_1.length;

          if (headings__2_length > 0) {
            const headings_2_numbers_1_length =
              doc.headings_1[headings__1_length - 1].headings_2[
                headings__2_length - 1
              ].numbers_1.length;
            doc.headings_1[headings__1_length - 1].headings_2[
              headings__2_length - 1
            ].numbers_1[headings_2_numbers_1_length - 1].numbers_2.push({
              text: content,
            });
          } else {
            doc.headings_1[headings__1_length - 1].numbers_1[
              headings__1_numbers_1_length - 1
            ].numbers_2.push({
              text: content,
            });
          }
          break;
        default:
          break;
      }
    }
  });

  return doc;
}

export default DocumentFileParser;
