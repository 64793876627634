import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SettingsIcon } from "../components/icons";

const Navbar = ({ waitListFeatureFlag, user, setUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const navigate = useNavigate();

  const handleLogoutClick = () => {
    sessionStorage.removeItem("user");
    setUser(null);
    setIsSettingsOpen(false);
    navigate("/login");
  };
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div id="nav" className="flex" data-aos="fade-down" data-aos-delay="500">
        {!waitListFeatureFlag ? (
          <>
            <div className="dropdown">
              <button class="dropbtn">
                <Link to="/" className="dropbtnlink">
                  CipherTEA
                </Link>
                <i class="fa fa-caret-down"></i>
              </button>
            </div>

            <div className="navMain-con">
              <div className="items-left">
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/docs" className="dropbtnlink">
                      Docs
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/api" className="dropbtnlink">
                      API
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/scenarios" className="dropbtnlink">
                      Scenarios
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="navMain-con">
              <div className="items-left">
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/wait-list" className="dropbtnlink">
                      Home
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/wait-list/signup" className="dropbtnlink">
                      Waitlist
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {!waitListFeatureFlag && (
          <>
            <div className="items-right">
              
              <div className="dropdown">
                <button class="dropbtn">
                  <Link to="/pricing" className="dropbtnlink">
                    Pricing
                  </Link>
                  <i class="fa fa-caret-down"></i>
                </button>
              </div>
              {!user && (
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to={"login"} className="dropbtnlink">
                      <button class="btn-login-nav">Login</button>
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              )}
              {user && (
                <div className="dropdown">
                  <button class="dropbtn">
                    <SettingsIcon
                      handler={() => setIsSettingsOpen(!isSettingsOpen)}
                    />
                    <i class="fa fa-caret-down"></i>
                  </button>
                  {isSettingsOpen && (
                    <div className="dropdown-menu">
                      <Link to="/settings" onClick={handleLinkClick}>
                        <span>Settings</span>
                      </Link>
                      <Link to="/">
                      <span onClick={handleLogoutClick}>Logout</span>
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* Mobile NavBar */}
      <div id="mobile-nav" className="flex">
        <div className="flex toggle-icon" onClick={() => setIsOpen(!isOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="icon-size"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
            />
          </svg>
        </div>
      </div>

      {isOpen && (
        <div className="nav-modal box-shadow">
          <div className="nav-modalContent">
            {!waitListFeatureFlag ? (
              <>
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/" className="dropbtnlink" onClick={handleLinkClick}>
                      CipherTEA
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/docs" className="dropbtnlink" onClick={handleLinkClick}>
                      Docs
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/api" className="dropbtnlink" onClick={handleLinkClick}>
                      API
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/scenarios" className="dropbtnlink" onClick={handleLinkClick}>
                      Scenarios
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/pricing" className="dropbtnlink" onClick={handleLinkClick}>
                      Pricing
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
                {!user && (
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to={"login"} className="dropbtnlink">
                      <button class="btn-login-nav" onClick={handleLinkClick}>Login</button >
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              )}
                {user && (
                <div className="dropdown">
                  <button class="dropbtn">
                    <SettingsIcon
                      handler={() => setIsSettingsOpen(!isSettingsOpen)}
                    />
                    <i class="fa fa-caret-down"></i>
                  </button>
                  {isSettingsOpen && (
                    <div className="dropdown-menu">
                      <Link to="/settings">
                        <span onClick={handleLinkClick}>Settings</span>
                      </Link>
                      <Link to="/">
                      <span onClick={handleLogoutClick}>Logout</span>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              </>
            ) : (
              <>
                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/wait-list" className="dropbtnlink">
                      Home
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>

                <div className="dropdown">
                  <button class="dropbtn">
                    <Link to="/wait-list/signup" className="dropbtnlink">
                      Waitlist
                    </Link>
                    <i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
