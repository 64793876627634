const FAQTabs = [
    {
        id: 1,
        title: "cryptography"
    },
    {
        id: 2,
        title: "Machine Learning"
    },
    {
        id: 3,
        title: "Data Security"
    },
    {
        id: 4,
        title: "Data Security"
    },
    {
        id: 5,
        title: "cryptography"
    },
    {
        id: 6,
        title: "Machine Learning"
    },
    {
        id: 7,
        title: "Data Security"
    },
    {
        id: 8,
        title: "Data Security"
    },
];

export default FAQTabs