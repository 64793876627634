export const USERS = [
    {
        id: 1,
        firstName: "Areej",
        lastName: "Atta",
        email: "areej@gmail.com",
        role: 'admin'
    },
    {
        id: 2,
        firstName: "Areej",
        lastName: "Atta",
        email: "aree@gmail.com",
        role: 'user'
    },
    {
        id: 3,
        firstName: "Areej",
        lastName: "Atta",
        email: "areej@gmail.com",
        role: 'user'
    },
];  