import React from "react";
import "../styles/api.css";

const CopyIcon = ({clickHandler}) => {
  return (
    <svg
      onClick={clickHandler}
      className="copy-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="404"
      height="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4 0.84-2.82 2H5c-1.1 0-2 0.9-2 2v14c0 1.1 0.9 2 2 2h14c1.1 0 2-0.9 2-2V5c0-1.1-0.9-2-2-2zm-7 0c0.55 0 1 0.45 1 1s-0.45 1-1 1-1-0.45-1-1 0.45-1 1-1zm1 14H7v-2h6v2zm0-4H7v-2h6v2zm0-4H7V7h6v2z" />
    </svg>
  );
};

export default CopyIcon;
