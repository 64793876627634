
const storyData = {
    title: 'Our Story',
    para1: `CipherTEA was started as a research project back in summer of 2021. Since then, CipherTEA publicly launched on 12/2023. The idea behind CipherTEA started with the premise that cryptography is a crucial part of technology, yet its implementation methods are still primitive. Although these methods by themselves are generally secure, due to the lack of innovation and the lack of standardized practices for performing cryptographic schemes, many companies have a hard time implementing them, particularly for securing data at rest. The research project conducted in 2021 was aimed at improving the implementation practices around cryptographic schemes by creating a secure system that can be relied upon as as single resource for performing cryptographic schemes via RESTful APIs. Atif Tirmizi, the founder of the company published his first peer-reviewed paper with his advisor towards the end of his Masters program. The idea came from him taking a course during his Masters' program in Computer Science for a course called ‘Encryption and Authentication’. This course covered nearly all areas of cryptography including symmetric and asymmetric encryption, decryption, hashing, key management and authentication. Upon taking this course, Atif couldn’t fathom how such a crucial aspect in modern technology could be so overlooked and not be as prevalent as it should be in modern computing education and corporate trainings. He used this to an advantage by writing two back-to-back papers and publishing them under his respectable professor, Dr. Osama AbuOmar.  Later, the publications for 2 research projects in IEEE became the foundations for this company and provided the credibility along with the confidence to move this from ideation and theory to a large-scale system which millions can potentially benefit from.`,
}

const personData = [
    {
        id: 1,
        titlePerson: 'Atif Tirmizi',
        position: 'CEO, Founding Engineer',
        image: '/assests/images/aboutMe/Atif.png',
        about: `Atif is the Founding Engineer and CEO for CipherTEA. He has a Masters Degree in Computer Science, from Lewis University in Romeoville, IL where he graduated in May of 
    2021. He has over 7 years of professional experience working as a Software Engineer for various companies and projects. Towards the end of his grad school, he wanted to do research
    with a professor that would become published. He had recently taken a course on ‘Encryption and Authentication’ which sparked his interests in the field due to the lack of innovation 
    in its' implementations and methods. He found an opportunity to combine this discipline with Machine Learning and APIs to formulate a research article, with his advisor. The rest 
    became history as the article eventually became published. Atif has a deep passion for Computer Science as it is a discipline for solving numerous problems. He also has a great 
    passion for Software Engineering as it involves creativity and constant learning. He developed a keen interest in cryptography after taking an encryption course in grad school. He 
    also believes that it is a very fascinating and mathematical discipline in computer science, involved with the many methods that are used encoding data in that can only be 
    interpreted by authorized users. The schemes involved in the cryptography field really mesmerized him and grew his interests, particularly because of the significant impacts of the 
    field and the lack of understanding many engineers have with it. 
    `,
        linkedin: 'https://www.linkedin.com/in/atif-tirmizi112/',
    },
    {
        id: 2,
        titlePerson: 'Faraz Tirmizi',
        position: 'CMO, Partner',
        image: '/assests/images/aboutMe/Faraz.png',
        about: `Faraz Tirmizi, the CMO and the mastermind behind the "constant reminders" that follow you reminding that you landed on this website, holds a Bachelor's degree in Business Administration from the Institute of Business Administration. With over 8 years of experience in Digital Marketing and Ecommerce, Faraz has collaborated with numerous B2B and B2C firms.
    In his dynamic career, Faraz has had the privilege of overseeing ad spends exceeding $5 million, and managing thousands of SKUs for diverse brands. His entrepreneurial journey began at the young age of 14 when he launched a clothing brand, sparking his enduring passion for Digital Marketing. Since then, Faraz has consistently embraced opportunities to collaborate with brands and individuals, aiding them in scaling and growing their presence in the E-commerce landscape.
    Determined to provide a comprehensive solution for e-commerce needs, Faraz co-founded a one-stop-shop agency. Eager to explore the synergies between AI and Marketing, he delved into the realm of AI by joining a startup, contributing to the blend of technology and marketing.
    In Faraz's world, Tech+Marketing isn't just a combination of expertise; it's a playground where he thrives on infusing innovation into strategic marketing. Whether perfecting ad campaigns, experimenting with AI-driven strategies, or guiding startups to success, Faraz's journey feels like discovering a unicorn in the realm of possibilities.`,
        linkedin: 'https://www.linkedin.com/in/faraz-tirmizi/',
    },
    {
        id: 3,
        titlePerson: 'Mike Ayoub',
        position: 'Principal Architect, Partner',
        image: '/assests/images/aboutMe/MikeAyoub.png',
        about: `Mike Ayoub is a seasoned technical engineer and solution architect with 8 years of experience in the technology sector, focusing on enterprise software solutions. Throughout his career at three leading software companies, Mike has excelled in engineering and, notably, as a professional services architect and consultant in recent years. Armed with a Bachelor of Science in Computer Programming and Information Systems, and a Master of Science in Business Intelligence and Analytics, he holds a wealth of knowledge and expertise. Mike's most recent professional endeavors have centered on data governance and data intelligence, where he has spearheaded innovative solutions for premier fortune 500 companies and federal agencies. Recently, Mike has expanded his professional repertoire as a partner of a cutting-edge cryptography company, cultivating a newfound passion for cryptography and its transformative potential in securing digital communications and data. This venture reflects his commitment to staying at the forefront of technological advancements and exploring new horizons in computer science. With a profound passion for computer science, problem-solving, and analytics, Mike enjoys continually embracing new challenges, ensuring that he is always learning and growing in his field. He excels at translating intricate technical concepts into clear, comprehensible language, making him an invaluable liaison between technical and non-technical stakeholders. Mike is enthusiastic about uncovering new opportunities in the realm of technology and cryptography to create a safer and more technologically advanced future.
    `,
        linkedin: 'https://www.linkedin.com/in/mike-ayoub/',
    },
    {
        id: 4,
        titlePerson: 'Areej Atta',
        position: 'Software Developer',
        image: '/assests/images/aboutMe/AreejAtta.png',
        about: `Meet Areej Atta, a talented software developer who holds a computer science degree from Sukkur IBA University. Her passion for creating captivating digital experiences 
        led her to specialize in front-end development. With a strong foundation in coding and design, Areej turns concepts into visually stunning, user-friendly websites. Her 
        dedication to staying at the forefront of web technology ensures that her projects are not only attractive but also cutting-edge, where she weaves her creative magic to 
        bring websites to life. Areej's journey as a developer began during her time at university, where she honed her skills and discovered her love for coding. Her ability to 
        seamlessly blend creativity with technical prowess sets her apart. Whether she's crafting elegant user interfaces or optimizing website performance, Areej's commitment to 
        excellence shines through in every line of code she writes, making her a true problem-solving artist in the realm of computer science.She's not just a developer; she's a 
        digital artist, 
        and her work is a testament to her passion for creating engaging online experiences.She takes pride in 
        fostering a sense of innovation and teamwork within her development projects, making her an invaluable asset to any team. Areej's dedication to frontend development 
        is not just about creating beautiful websites but also about nurturing a community of like-minded individuals who share her enthusiasm for creating exceptional 
        digital experiences.
    `,
        linkedin: 'https://www.linkedin.com/in/areej-atta-156354164/',
    },
]

export{storyData, personData}