import React, { useEffect } from 'react'
import '../../styles/legal.css'
import LegalData from '../../staticData/LegalData';

const Legal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='privacy-container'>
        <div style = {{marginBottom: '20px'}} className='privacy-header'>
            LEGAL
        </div>
        <div className='privacy-content-container'>
        {Object.entries(LegalData).map(([key, value]) => (
          <div key={key} className='p-container'>
            <div className='p-heading'>{value.heading}</div>
            <p className='p-content'>{value.content}</p>
          </div>
        ))}
        </div>
    </div>
  )
}

export default Legal