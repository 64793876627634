import React, { useState, useEffect } from "react";
import "../../../styles/faq.css";
import Questions from "../../../components/QuestionAndAnswers";
import Tabs from "../../../components/QuestionCategoriesTabs";
import { Link } from "react-router-dom";

import {
  cryptographyQuestions,
  DataSecurityQuestions,
  MachineLearningQuestions,
  FAQTabs,
} from "../../../staticData/FAQs/CategoryAndTabsExporter";

const FAQs = () => {
  const [selectedTab, setSelectedTab] = useState(FAQTabs[0]);
  const [questions, setQuestions] = useState(cryptographyQuestions);

  // update questions based on selected tab
  useEffect(() => {
    switch (selectedTab.title){
      case "cryptography":
        setQuestions(cryptographyQuestions);
        break;
      case "Machine Learning":
        setQuestions(MachineLearningQuestions);
        break;
      case "Data Security":
        setQuestions(DataSecurityQuestions);
        break;
    }

    // Scroll to the top of the page when the selected tab changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedTab]);

  return (
    <section class="main-faq-section">
      <div className="content-faq-section">
        <div className="faq-title">FAQ</div>

        {/* Tabs Component */}
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabItems={FAQTabs}
        />

        {/* Question Answer components */}
        <Questions questions={questions} setQuestions={setQuestions} />

        <div className="last-text">
          Still have questions? <span className="last-contact"><Link to="/ContactSupport"  >Contact Us</Link></span>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
