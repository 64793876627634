const DataSecurityQuestions = [
    {
        id: 1,
        question: "If there is a data breach on my end, is CipherTEA responsible for it?",
        answer: "No. CipherTEA does not provide mechanisms to prevent data breaches. Rather, it provides mechanisms that in case of a data breach, the data encrypted or hashed through the cipherTEA system protocol, cannot be deciphered by any means. Therefore, it’s completely the responsibility of the client to make sure to follow proper practices to best prevent data breaches. CIpherTEA is the best approach moving forward in data protection because it can make data breaches potentially meaningless.",
        isOpen: false,
    },
    {
        id: 2,
        question: " Is the data that I need to encrypt and decrypt visible or exposed to CipherTEA’s servers?",
        answer: "No. CipherTEA uses a zero-knowledge technology to perform cryptography practices. Basically, you install a portion of the system on your local machine or servers to perform the encryption. On our end, we have authentication and handshake servers that are used via secure RESTful APIs to complete the encryption or decryption process. From our end, we provide certain mappings to complete the encryption process which produces the ‘CipherTEA’ text.  You first makes a GET method API call by passing an authentication token. Once we verify the authentication, we send the mappings to the clients to complete their encryption process on their own machines. This way, we don’t have any visibility of your data that needs to be encrypted or decrypted. This protocol provides most efficient security.",
        isOpen: false,
    },
    {
        id: 3,
        question: "What credibility does CipherTEA have for its original protocol?",
        answer:"CipherTEA came out as a research project. This company was built upon the foundations of two research projects that went through peer-reviewed processes for an approval. The articles behind the research were first written by a masters student who has been a Software Engineer by several years and another author who is a Ph.D. advisor and tenured professor. Both the research articles went through a rigorous peer review process from prestigious and credible conferences from IEEE (Institute of Electrical and Electronics Engineers). Moreover, the protocol has been thoroughly investigated by security and software engineers and continues to improve on its practices. Please refer to the Research page for more information.",
        isOpen: false,
    },
];

export default DataSecurityQuestions;