function parseFile(fileData, currentDate) {
  // Initialize an object to store the parsed data
  const blog = {
    title: "",
    author: "",
    date: currentDate || getCurrentDate(),
    hashtags: [],
    posts: [],
  };

  // Create a temporary variable to store the current post data
  let currentPost = null;

  // Split the file data into lines
  const lines = fileData.split("\n");

  // Iterate through each line
  lines.forEach((line) => {
    // Extract the tag and content from the line
    const match = line.match(/<([^>]+)>([^<]*)<\/\1>/);

    if (match) {
      const [, tag, content] = match;

      // Handle different tags
      switch (tag.toLowerCase()) {
        case "title":
          blog.title = content;
          break;
        case "author":
          blog.author = content;
          break;
        case "date":
          blog.date = content.trim() || getCurrentDate();
          break;
        case "hashtag":
          blog.hashtags.push(content);
          break;
        case "header":
          currentPost = { headerData: { header: content, text: "" } };
          blog.posts.push(currentPost);
          break;
        case "header2":
          if (currentPost) {
            currentPost.header2Data = { header: content, text: "" };
          }
          break;
        case "text":
          if (currentPost && currentPost.header2Data) {
            currentPost.header2Data.text += content;
          } else if (currentPost && currentPost.headerData) {
            currentPost.headerData.text += content;
          }
          break;
        case "image":
          if (currentPost) {
            currentPost.Image = content;
          }
          break;
        case "parentimage":
          if (currentPost) {
            currentPost.ParentImage = content;
          }
          break;
        case "subheading":
          if (currentPost) {
            currentPost.subheading = content;
          }
          break;
        case "bullet":
          if (currentPost) {
            currentPost.bullets = currentPost.bullets || [];
            currentPost.bullets.push({
              bullet: content,
            });
          }
          break;
        case "subbullet":
          if (currentPost) {
            if (
              currentPost.bullets[currentPost.bullets.length - 1] &&
              currentPost.bullets[currentPost.bullets.length - 1].sub_bullet ===
                undefined
            ) {
              currentPost.bullets[currentPost.bullets.length - 1].sub_bullet =
                [];
            }

            currentPost.bullets[currentPost.bullets.length - 1] &&
              currentPost.bullets[
                currentPost.bullets.length - 1
              ].sub_bullet.push(content);
          }
          break;
        default:
          break;
      }
    }
  });
  return blog;
}
function getCurrentDate() {
  const now = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  return now.toLocaleDateString("en-US", options);
}

export default parseFile;
