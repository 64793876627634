import React from "react";
import "../styles/AlertBox.css";

const AlertBox = ({ message, onClose , type}) => {
  const getAlertStyles = (type) => {
    const baseStyles = {
      success: {
        headingColor: "#27ae60", // Green for the success heading
        buttonColor: "#27ae60", // Green button
        buttonHoverColor: "#1e8449", // Darker green on hover
        headingText: "Success",
      },
      error: {
        headingColor: "#e74c3c", // Red for the error heading
        buttonColor: "#e74c3c", // Red button
        buttonHoverColor: "#c0392b", // Darker red on hover
        headingText: "Error",
      },
    };

    return baseStyles[type] || baseStyles.error; // Default to error if type is undefined or invalid
  };

  const alertStyles = getAlertStyles(type);

  return (
    <div className="alert-box">
      <div className="alert-content">
        <h2 className="alert-heading" style={{ color: alertStyles.headingColor }}>
          {alertStyles.headingText}
        </h2>
        <p className="alert-paragraph">{message}</p>
        <button
          className="alert-button"
          style={{ backgroundColor: alertStyles.buttonColor }}
          onMouseOver={(e) => (e.target.style.backgroundColor = alertStyles.buttonHoverColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = alertStyles.buttonColor)}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AlertBox;