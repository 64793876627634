import React from 'react'

const TextArea = ({value, onChange, name, rows=4, cols=50, placeholder}) => {
  return (
    <textarea 
        className='input-text-area'
        placeholder={placeholder}
        name={name} 
        rows={rows}
        cols={cols}
        value={value} 
        onChange={onChange}
    />
  )
}

export default TextArea