import '../../styles/Research.css'
import React from 'react'
import TextCard from '../../components/TextCard'
import { background, researchPapers } from '../../staticData/ResearchData'
import SvgLinks from '../../components/SvgLinks'
import { Link } from 'react-router-dom'

const Research = () => {
  return (
    <div class="consistent-margin">
      <TextCard data={background}/>

      <div class="grid cols-1 lg-cols-3 py-10">
      {researchPapers.map((data) => (
      <div key={data.id} class="container mx-auto" id="research">
      <div className="main-research-container" data-aos="fade-up">
          <div class="researchPaper">
            <Link to={`/ResearchChild/${data.id}`} style={{color: 'black'}}> <p>{data.paper}</p></Link>
            <br />
            <br/>
            <div class="publisher-arrow">
            <div class="publisher">
                <p>
                  {data.Publisher}
                  <br />
                  {data.Author}
                  <br />
                  {data.Date}
                </p>
              </div>

              <SvgLinks link={data.PaeprLink}></SvgLinks>
            </div>
            </div>
          </div>
        </div>
       ))}
      </div>


      </div>

  )
}

export default Research
