const AccountAndProfile = [
    {
      id: 1,
      question:
        "What makes CipherTEA more secure than standard encryption mechanisms?",
      answer:
        "CipherTEA is a research project. Although most standard cryptography algorithms and principals involved in modern cryptography are generally secure, their implementation practices are not. CipherTEA solves this by creating a system that is to be relied on, rather than the weak implementation practices most businesses use. It does this be creating a fully secure system that uses obfuscation techniques on top of existing modes of encryption; that can only be interpreted by the underlying framework for decryption and authentication. Thus, in a case where an adversary gets access to the data, they will not be able to decipher the encrypted data. ",
      isOpen: false,
    },
    {
      id: 2,
      question: "Does CipherTEA store any of my data?",
      answer:
        "No. As of yet, CIpherTEA is not a cloud or database provider. Rather, it provides the means to easily and securely store your data in your server(s) of choice. It simply acts as an intermediary between you and your data storage systems where you can perform encryption right before that data is secured. ",
      isOpen: false,
    },
    {
      id: 3,
      question:
        "How does CipherTEA prevent hackers from accessing or decrypting Client data?",
      answer:
        "CipherTEA can’t prevent hackers from accessing your data. However, in the event of hacking or a data breach, the breached data encrypted through our system is meaningless because the decryption process can only be fully completed through the CipherTEA’s authentication servers.",
      isOpen: false,
    },
  ];
  
  export default AccountAndProfile;
  